import "@rmwc/drawer/styles";
import "@rmwc/fab/styles";
import '@rmwc/list/styles';
import { selectToken } from "App/Authentification/Store/selectors";
import { URL_BASE } from "App/Constants";
import { setBackVisible } from "App/Header/Store/actions";
import { setGeolocationBlocked } from "App/Store/Geolocation/actions";
import { selectIsGeolocationBlocked } from "App/Store/Geolocation/selectors";
import { showError } from "App/Toast/Toast";
import { Distribution } from "Distribution/Components/Distribution";
import { RESSOURCE_TERMINALS } from "Map/API/MapApi";
import { MapComponent } from "Map/Components/MapV2";
import {
  ICON_ACTIVE,
  ICON_HS,
  ICON_INACTIVE,
  ZOOM_ADDRESS,
  ZOOM_COUNTRY
} from "Map/Constants";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { GET } from "Request/Components/RequestUtils";
import { GET_LIST_WITH_PARAMS, useJsonRequest } from "Request/Components/useJsonRequest";
import { selectDataResourceReceived } from "Request/Store/selectors";
import "./Accueil.css";
import { BottomNavBar } from "./BottomNavBar";
import { Menu } from "./MenuSelector";

// #region CONSTANTES
// Indice des TabBar
const TAB_MAP = 0;
const TAB_DISTRIBUTION = 1;
// Erreur de géolocalisation
const GEOLOCATION_BLOCKED = 1;
// #endregion

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher la page d'accueil
 * @category Accueil
 */
function Accueil() {
  //#region INITIALISATION
  // Récupération des props
  const location = useLocation();
  const selectedTab = location.state ? location.state.selectedIndexTab : null;
  const urlParams = new URLSearchParams(window.location.search);

  // Initialisation du state
  const [mapInfo, setMapInfo] = useState({ coordinates: { lat: 46.82407949355533, lng: 2.4905130531397117 }, zoom: ZOOM_COUNTRY, showMarker: false });
  const [selectedIndexTab, setSelectedIndexTab] = useState(selectedTab ? selectedTab : TAB_MAP);

  // Initialisation des selecteurs
  const dataReceived = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_TERMINALS)); // Données issues de la requête
  const isGeolocationBlocked = useSelector((state) => selectIsGeolocationBlocked(state));
  const token = useSelector((state) => selectToken(state));

  // Initialisation des références
  const terminaux = useRef([]);
  const terminauxSelector = useRef([]);
  const timer = useRef(-1);

  // Initialisation de l'indicateur de géolocalisation bloquée
  // Le sélecteur isGeolocationBlocked n'est pas a jour dans le setTimeOut, on est obligé de le forcer
  //cf. https://upmostly.com/tutorials/settimeout-in-react-components-using-hooks
  const geolocationRef = useRef(isGeolocationBlocked);
  geolocationRef.current = isGeolocationBlocked;

  // Récupération de l'historique
  const history = useHistory();

  // Récupération du dispatch
  const dispatch = useDispatch();

  // Traduction i18n
  const { t } = useTranslation();
  //#endregion

  // #region UTILS
  const getLocation = () => {
    if ("geolocation" in navigator) {
      // Suppression des tentatives
      clearTimeout(timer.current);
      // Récupération de la position courante
      navigator.geolocation.getCurrentPosition(
        // Succès
        (position) => {
          updateMapInfo(position)
        },
        // Erreur
        (err) => { handleErrorLocation(err) }
      );
    } else {
      // Géolocalisation inaccessible
      showError("Le navigateur ne peut pas vous géolocaliser");
      // Nouvelle tentative
      timer.current = setTimeout(() => {
        getLocation();
      }, 1000);
    }
  };

  // Récupère les options de la barre de recherche
  const getSelectOptions = (list) => {
    let options = [];

    list.forEach((marker) => {
      options.push({
        active: marker.active,
        label: marker.name,
        lat: marker.location.lat,
        lng: marker.location.lng,
        value: marker.id
      })
    });

    terminauxSelector.current = options;
  };

  // Récupération de l'icône du terminal
  const getTerminalIcon = (etatTerminal) => {
    switch (etatTerminal) {
      case 0:
        return ICON_INACTIVE

      case 1:
        return ICON_ACTIVE

      case 2:
        return ICON_HS

      default:
        break;
    }
  };

  const getUrlParameters = () => {
    let result = [];

    let idTerminaux = urlParams.get("idTerminal");
    if (idTerminaux) {
      result.push(`idTerminal=${idTerminaux}`);
    }

    let idTypeControl = urlParams.get("idTypeControl");
    if (idTypeControl) {
      result.push(`idTypeControl=${idTypeControl}`);
    }

    return result;
  }

  // Mise à jour des infos de la map
  const updateMapInfo = (position) => {
    let newMapInfo = {};

    // Mise à jour des coordonnées
    newMapInfo.coordinates = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    };

    // Mise à jour du zoom
    newMapInfo.zoom = ZOOM_ADDRESS;

    // Affichage du marker
    newMapInfo.showMarker = true;

    // Mise à jour du state
    setMapInfo(newMapInfo);
  };
  // #endregion

  // #region REQUEST
  // Requête de récupération des terminaux
  const requetGetTerminals = useJsonRequest({
    command: GET,
    getMode: GET_LIST_WITH_PARAMS,
    resource: RESSOURCE_TERMINALS,
    url: URL_BASE
  });
  // #endregion

  // #region EVENTS
  // Sélection d'une borne
  const handleClickBorne = (borne) => {
    if (!borne.id) {
      return;
    }

    if (!borne.active) {
      showError(t("accueil.borneNotAvailable"));
      return;
    }

    history.push({
      pathname: "/achat",
      state: {
        borne: borne,
        codeAcces: "",
        dateValidite: null,
        depassement: 0,
        immatriculation: "",
        rachat: false,
        servicesActifs: []
      }
    });
  };

  // Gestion de l'erreur de géolocalisation
  const handleErrorLocation = (error) => {
    if (!geolocationRef.current) {
      // Enregistrement dans le store
      dispatch(setGeolocationBlocked(true));
      if (error.code === GEOLOCATION_BLOCKED) {
        showError("Vous n'avez pas autorisé l'application à accéder à votre géolocalisation");
      } else {
        showError(error.message, "Impossible d'accéder à votre géolocalisation");
      }
    }

    timer.current = setTimeout(() => {
      getLocation();
    }, 1000);
  };
  // #endregion

  // #region HOOK D'EFFET
  // On cache le bouton back du header
  useEffect(() => {
    dispatch(setBackVisible(false));
  }, [dispatch]);

  // Lancement de la requête
  useEffect(() => {
    // récupération du paramètre d'url
    let urlParams = getUrlParameters();

    // Navigation vers la connexion si pas de token
    if (token && !token.token) {
      history.push({
        pathname: "/connexion",
        state: { mapParams: location.search }
      });
      return;
    }

    // Sortie si tab distribution
    if (selectedIndexTab !== TAB_MAP) {
      return;
    }

    // Récupération des terminaux
    requetGetTerminals(urlParams);

    // Récupération de la position utilisateur
    timer.current = setTimeout(() => {
      getLocation();
    }, 1000);
    return () => clearTimeout(timer.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, token, requetGetTerminals, selectedIndexTab]);

  // Récupération des terminaux
  useEffect(() => {
    if (!dataReceived) {
      return;
    }

    let d = [...dataReceived];
    let markerList = [];

    d.forEach(terminal => {
      // Formatage de la localisation
      let location = terminal.gpsTerminal.split(',');

      // Formatage de la resource terminal
      let newTerminal = {
        active: terminal.etatTerminal === 1 ? true : false,
        icon: getTerminalIcon(terminal.etatTerminal),
        id: terminal.idTerminal,
        idModePaiement: terminal.idModePaiement,
        location: {
          lat: parseFloat(location[0]),
          lng: parseFloat(location[1])
        },
        name: terminal.nomTerminal,
        serviceList: terminal.serviceList,
        zones: terminal.zones
      }

      markerList.push(newTerminal);
    });

    if (markerList.length === 1) {
      handleClickBorne(markerList[0]);
      return;
    }

    // Enregistrement des terminaux formatés
    terminaux.current = markerList;
    getSelectOptions(markerList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReceived]);
  // #endregion

  // #region INTERFACE
  return (
    <div >
      <div className="Accueil-Container">
        {(selectedIndexTab === TAB_MAP) &&
          <>
            <MapComponent
              center={mapInfo.coordinates}
              isMarkerShown={mapInfo.showMarker}
              markers={terminaux.current}
              terminauxSelector={terminauxSelector.current}
              onChangeActiveTab={setSelectedIndexTab}
              onClick={(borne) => { handleClickBorne(borne) }}
              selectedIndexTab={selectedIndexTab}
              zoom={mapInfo.zoom}
            />
            <Menu />
          </>
        }
        {
          (selectedIndexTab === TAB_DISTRIBUTION) &&
          <Distribution borne={terminaux.current} />
        }
      </div >
      <BottomNavBar
        activeTabIndex={selectedIndexTab}
        onChangeActiveTab={(index) => setSelectedIndexTab(index)}
      />
    </div>
  );
  // #endregion
};
// #endregion

export { Accueil };

