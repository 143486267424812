/**
 * Sélecteurs pour la gestion du token
 * @category Store
 * @hideconstructor
 */
class TokenStoreSelector {
  /**
   * Sélection de l'objet contenant les informations du token
   * @param {object} state state de l'application
   * @returns {TokenObject} Informations de session
   */
  static selectToken(state) {
    return state.manageToken.token;
  }

  /**
   * Sélection de la date et heure de la récupération du token
   * @param {object} state state de l'application
   * @returns {date} Variable contenant la date et heure de la récupération du token
   */
  static selectTokenDate(state) {
    if (!TokenStoreSelector.selectToken(state)) {
      return null;
    }

    return TokenStoreSelector.selectToken(state).date;
  }

  /**
   * Sélection du token
   * @param {object} state state de l'application
   * @returns {string} Variable contenant le token
   */
  static selectTokenValue(state) {
    if (!TokenStoreSelector.selectToken(state)) {
      return null;
    }

    return TokenStoreSelector.selectToken(state).token;
  }

}

/**
 * Sélecteurs pour la gestion du token admin
 * @category Store
 * @hideconstructor
 */
class TokenAdminStoreSelector {
  /**
   * Sélection de l'objet contenant les informations du token
   * @param {object} state state de l'application
   * @returns {TokenAdminObject} Informations de session
   */
  static selectTokenAdmin(state) {
    return state.manageToken.tokenAdmin;
  }

  /**
   * Sélection de la date et heure de la récupération du tokenAdmin
   * @param {object} state state de l'application
   * @returns {date} Variable contenant la date et heure de la récupération du tokenAdmin
   */
  static selectTokenAdminDate(state) {
    if (!TokenAdminStoreSelector.selectTokenAdmin(state)) {
      return null;
    }

    return TokenAdminStoreSelector.selectTokenAdmin(state).date;
  }

  /**
   * Sélection du tokenAdmin
   * @param {object} state state de l'application
   * @returns {string} Variable contenant le tokenAdmin
   */
  static selectTokenAdminValue(state) {
    if (!TokenAdminStoreSelector.selectTokenAdmin(state)) {
      return null;
    }

    return TokenAdminStoreSelector.selectTokenAdmin(state).tokenAdmin;
  }

}


/**
 * Sélecteurs pour la gestion du token
 * @category Store
 * @hideconstructor
*/
class UserStoreSelector {
  /**
   * Sélection de l'objet contenant les informations du token
   * @param {object} state state de l'application
   * @returns {UserObject} Informations de session
  */

  /**
   * Sélection de l'objet contenant les informations du token
   * @param {object} state state de l'application
   * @returns {TokenObject} Informations de session
   */
  static selectUser(state) {
    return state.manageToken.user;
  }

  /**
   * Sélection du token
   * @param {object} state state de l'application
   * @returns {string} Variable contenant le token
   */
  static selectUserId(state) {
    return UserStoreSelector.selectUser(state).id;
  }

}

export const selectToken = TokenStoreSelector.selectToken;
export const selectTokenDate = TokenStoreSelector.selectTokenDate;
export const selectTokenValue = TokenStoreSelector.selectTokenValue;
export const selectTokenAdmin = TokenAdminStoreSelector.selectTokenAdmin;
export const selectTokenAdminDate = TokenAdminStoreSelector.selectTokenAdminDate;
export const selectTokenAdminValue = TokenAdminStoreSelector.selectTokenAdminValue;
export const selectUser = UserStoreSelector.selectUser;
export const selectUserId = UserStoreSelector.selectUserId;
