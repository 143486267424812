import { CollapsibleList, SimpleListItem } from "@rmwc/list";
import '@rmwc/list/styles';
import { MODES, SERVICES_TYPES } from "Achat/Constants";
import PropTypes from "prop-types";
import { selectIcon } from "Shared/Utils/Icon";
import "./AchatList.css";
import { AchatListItem } from "./AchatListItemNew";

// #region FONCTION COMPOSANT
/**
* Composant permettant de
* @class
* @category AchatList
*/
function AchatList(props) {
  // #region INITIALISATION
  const {
    categorie,
    categorieIndex,
    onChange,
    mode,
    parentSelected
  } = props;
  // #endregion

  // #region UTILS
  // Renvoie le titre de la catégorie en fonction du mode d'affichage de l'écran
  const getCategorieName = () => {
    switch (mode) {
      case MODES.ENFANT:
        if (parentSelected) {
          return parentSelected.nom;
        }
        break;

      case MODES.PARENT:
      case MODES.TOTAL:
      default:
        return categorie.name;
    }
  };

  // Renvoie si le service doit être affiché
  const isServiceVisible = (service) => {
    switch (mode) {
      case MODES.PARENT:
        return !service.idServiceParent;

      case MODES.ENFANT:
        return service.idServiceParent;

      case MODES.TOTAL:
        return service.montant;
      default:
        break;
    }
  }
  // #endregion

  // #region EVENTS
  // Modification d'une catégorie
  const handleChange = (service, index) => {
    let d = categorie;

    if (!d.services && !d.services[index]) {
      return;
    }
    d.services[index] = service;

    // Vérification si service de stationnement acheté
    const haveParkingService = d.services.filter(service => (service.idServiceType === SERVICES_TYPES.PARKING) && service.selected).length > 0;

    // Mise à jour des données
    onChange(d, categorieIndex, haveParkingService);
  }
  // #endregion

  // #region INTERFACE
  return (
    <CollapsibleList
      key={categorie.id}
      defaultOpen
      handle={
        <SimpleListItem
          className="Achat-HeaderList"
          graphic={selectIcon(categorie.id)}
          metaIcon="chevron_right"
          text={getCategorieName()}
        />
      }
    >
      {
        categorie.services.map((item, index) => {
          return isServiceVisible(item) ? (
            < AchatListItem
              key={item.idService}
              onChange={handleChange}
              service={item}
              mode={mode}
              serviceIndex={index}
            />
          )
            :
            null
        })
      }
    </ CollapsibleList>
  );
  // #endregion
};
// #endregion

// #region PROPRIETES
/**
* Type des propriétés de {@link AchatList}
  * @typedef {Object} AchatList.propTypes
  * @property {object} categorie Informations de la catégorie
  * @property {number} categorieIndex Index de la catégorie
  * @property {object} mode mode de l'écran
 */

AchatList.propTypes = {
  categorie: PropTypes.object.isRequired,
  categorieIndex: PropTypes.number.isRequired,
  mode: PropTypes.object.isRequired,
};

/*
Valeurs par défaut des propriétés
*/
// #endregion

export { AchatList };

