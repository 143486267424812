import {
  CollapsibleList,
  ListItem,
  ListItemGraphic,
  ListItemMeta,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemText,
  SimpleListItem
} from "@rmwc/list";
import { RESSOURCE_HISTO_ACHATS } from "Achat/API/AchatApi";
import { URL_BASE } from "App/Constants";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GET } from "Request/Components/RequestUtils";
import { GET_LIST_WITH_PARAMS, useJsonRequest } from "Request/Components/useJsonRequest";
import { selectDataResourceReceived } from "Request/Store/selectors";
import { selectIcon } from "Shared/Utils/Icon";
import "./HistoAchats.css";

// #region FONCTION COMPOSANT
/**
* Composant permettant d'afficher l'historique des achats d'un utilisateur
* @class
* @category Achat
*/
function HistoAchats() {
  // #region INITIALISATION
  // Initialisation des state
  const [data, setData] = useState();

  // Initialisation des sélecteurs
  const dataReceived = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_HISTO_ACHATS)); // Données issues de la requête);
  // #endregion

  // #region UTILS
  const getPrix = (quantite, prix) => {
    return quantite * prix;
  };
  // #endregion

  // #region REQUEST
  // Requête de récupération des achats de l'utilisateur connecté
  const requestGetHistoAchats = useJsonRequest({
    command: GET,
    getMode: GET_LIST_WITH_PARAMS,
    resource: RESSOURCE_HISTO_ACHATS,
    url: URL_BASE,
  });
  // #endregion

  // #region HOOK D'EFFET
  // Requête des achats
  useEffect(() => {
    requestGetHistoAchats();
  }, [requestGetHistoAchats]);

  // Réception des données
  useEffect(() => {
    if (!dataReceived) {
      return;
    }

    let d = dataReceived;

    setData(d);
  }, [dataReceived]);

  // #endregion

  // #region INTERFACE
  return (
    <div className="HistoAchats-Container">
      {/* Title */}
      <h2 className="HistoAchats-Title">Historique des achats</h2>

      {/* Item */}
      {data && data.map((item, index) => (
        <CollapsibleList
          key={index}
          handle={
            <SimpleListItem
              className="HistoAchats-List-Header"
              metaIcon="chevron_right"
              text={`${moment(item.dateAchat).format("DD/MM/YYYY")} - ${item.nomSite} ${item.montant ? `- ${item.montant} €` : ''}`}
              style={{ height: "60px" }}
            />
          }
        >
          {
            item.detailAchats.map((service) => (
              <ListItem
                className="HistoAchats-List-Item"
                key={service.idService}
                ripple={false}
                style={{ height: "80px" }}
              >
                <ListItemGraphic icon={selectIcon(service.idServiceType)} />
                <ListItemText>
                  <ListItemPrimaryText>{service.nomService}</ListItemPrimaryText>
                  <ListItemSecondaryText>{service.quantite} {service.nomServiceType}</ListItemSecondaryText>
                </ListItemText>
                <ListItemMeta style={{ fontSize: 18 }} className="HistoAchats-Item-Price">
                  {getPrix(service.quantite, service.tarifService)} €
                </ListItemMeta>
              </ListItem>
            ))
          }
        </CollapsibleList>
      ))
      }
    </div >
  );
  // #endregion
};
// #endregion

export { HistoAchats };

