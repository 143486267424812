// Actions
export const CLEAR_TOKEN = "CLEAR_TOKEN";
export const SET_TOKEN = "SET_TOKEN";
export const CLEAR_TOKEN_ADMIN = "CLEAR_TOKEN_ADMIN";
export const SET_TOKEN_ADMIN = "SET_TOKEN_ADMIN";
export const SET_USER = "SET_USER";

// Statut initial
export const INITIAL_STATE = {
  /**
   * Objet de token sauvegardé dans le store
   * @category Store
   * @typedef {object} TokenObject
   * @property {string} [token=null] Token
   * @property {date} [date=null] Date et heure de la récupération du token
   */
  token: {
    token: null,
    date: null
  },

  /**
  * Objet de tokenAdmin sauvegardé dans le store
   * @category Store
   * @typedef {object} TokenAdminObject
   * @property {string} [tokenAdmin=null] Token
   * @property {date} [date=null] Date et heure de la récupération du token
   */
  tokenAdmin: {
    tokenAdmin: null,
    date: null
  },

  /**
 * Objet de user sauvegardé dans le store
 * @category Store
 * @typedef {object} UserObject
 * @property {string} [id=null] Id de l'utilisateur
 */
  user: {
    id: 1,
  }
};
