import { Fab } from "@rmwc/fab";
import { List } from "@rmwc/list";
import { Snackbar, SnackbarAction } from "@rmwc/snackbar";
import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";
import { RESSOURCE_ACHATS, RESSOURCE_SERVICES, RESSOURCE_TARIFS, RESSOURCE_ZONE } from "Achat/API/AchatApi";
import { MODES, PAYZEN, PAYZEN_TPE, SERVICES_TYPES, TPE, TYPE_ACHAT, UNITE_TYPES } from "Achat/Constants";
import { setAchat } from "Achat/Store/actions";
import { selectToken } from "App/Authentification/Store/selectors";
import { URL_BASE } from "App/Constants";
import { setBackVisible } from "App/Header/Store/actions";
import { Modale } from "App/Modale/Components/Modale";
import { showError } from "App/Toast/Toast";
import _ from "lodash";
import moment from "moment";
import { PaymentForm } from "PaymentForm/Components/PaymentForm";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectDataReferentielServiceTypes, selectDataReferentielUniteTypes } from "Referentiel/Store/selectors";
import { GET, POST } from "Request/Components/RequestUtils";
import { GET_LIST_WITH_PARAMS, useJsonRequest } from "Request/Components/useJsonRequest";
import { selectDataResourceReceived, selectDataResourceUpdated } from "Request/Store/selectors";
import { ConfirmModal } from "Shared/ConfirmModal";
import { TicketClientModale } from "TicketClient/Components/TicketClientModale";
import "./Achat.css";
import { AchatList } from "./AchatListNew";
import { DescriptifBorne } from "./DescriptifBorne";
import { ModaleAttentePaiementTPE } from "./ModaleAttentePaiementTPE";
import { ModaleTypePaiement } from "./ModaleTypePaiement";

// #region CONSTANTES
// Status du paiement
const PAID = "00";
// #endregion

// #region FONCTION COMPOSANT
/**
* Composant permettant d'afficher la liste pour achat sur une borne
* @class
* @category Achat
*/
function Achat() {
  // #region INITIALISATION
  // Récupération des props
  const location = useLocation();
  const borne = location.state.borne;

  // Initialisation du state
  const [data, setData] = useState(); // Données de l'écran
  const [dataServices, setDataServices] = useState(null); // Données des services
  const [dataTarifs, setDataTarifs] = useState(null); // Données du tarif
  const [dataZone, setDataZone] = useState(null); // Données de la zone
  const [immatriculation, setImmatriculation] = useState(location.state.immatriculation ?? "");
  const [mode, setMode] = useState(MODES.PARENT); // Modes de l'écran (parent/enfant/total)
  const [montantTotal, setMontantTotal] = useState(0); // Montant total des services sélectionnés

  // Modales
  const [messageImmatriculation, setMessageImmatriculation] = useState(false);
  const [modaleAttentePaiementTPEVisible, setModaleAttentePaiementTPEVisible] = useState(false);
  const [modaleChoixPaiementVisible, setModaleChoixPaiementVisible] = useState(false);
  const [modaleImmatriculation, setModaleImmatriculation] = useState(false);
  const [modalePayzenVisible, setModalePayzenVisible] = useState(false);
  const [modaleTicketPayzenVisible, setTicketClientModaleVisible] = useState(false);


  // Initialisation des références
  const achatTPEId = useRef(null); // Id de l'achat dans le cadre d'un paiement TPE
  const currentAchat = useRef(null); // Achat pour le ticket PAYZEN
  const devise = useRef(null); // Devise du terminal
  const hasParking = useRef(false) // Définit si il y a un service de stationnement sélectionné
  const hasTax = useRef(false) // Définit si il y a une taxe sur au moins un service
  const nbPersonne = useRef(0);
  const typePaiement = useRef(null); // Type de paiement

  // Initialisation des sélecteurs
  // Retours API
  const dataReceivedServices = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_SERVICES)); // Données issues de la requête de zone
  const dataReceivedTarifs = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_TARIFS)); // Données issues de la requête de zone
  const dataReceivedZone = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_ZONE)); // Données issues de la requête de zone
  const dataUpdatedAchats = useSelector((state) => selectDataResourceUpdated(state, RESSOURCE_ACHATS)); // Données issues de la requête

  // Informations enregistrés
  const token = useSelector((state) => selectToken(state));
  const serviceTypes = useSelector((state) => selectDataReferentielServiceTypes(state));
  const uniteTypes = useSelector((state) => selectDataReferentielUniteTypes(state));

  // Initialisation du dispatch
  const dispatch = useDispatch();

  // Traduction i18n
  const { t } = useTranslation();
  // #endregion

  // #region UTILS
  // Renvoie l'icône du bouton de validation
  const getButtonIcon = () => {
    switch (mode) {
      case MODES.PARENT:
        return "shopping_cart"

      case MODES.ENFANT:
      case MODES.TOTAL:
      default:
        return "payment"
    }
  };

  // Renvoie le label dans les boutons de validation / retour
  const getButtonLabel = (isCancel = false) => {
    if (isCancel) {
      return t("achat.cancel");
    }

    let result = t("achat.validation");
    if (hasTax.current) {
      result = t("achat.validationTaxe");
    }

    if (montantTotal) {
      result = result + ` ${(montantTotal ? "(" + montantTotal + " €)" : "").trim()}`
    }

    return result;
  };

  // Renvoie la liste des services sélectionnés
  const getServicesSelected = () => {
    let servicesSelected = [];

    data.forEach(categorie => {
      categorie.services.forEach(service => {
        if (service.selected) {
          servicesSelected.push({
            ...service,
            notify: 0,
            devise: service.tarif.devise,
            secondes: service.tarif.secondes,
            //  serviceType: service.idServiceType
          });
        }
      });
    });

    return servicesSelected;
  };

  // Initialisation des données
  const initializeData = useCallback(() => {
    // Init services + tarifs;
    let services = dataServices;
    let tarifs = dataTarifs;

    // Pour chaque service, on injecte les tranches de tarif correspondantes
    services.forEach(service => {
      let tarif = tarifs.find(tarif => tarif.idServiceType === service.idServiceType);
      service.tarif = tarif;

      if (service.idServiceType === SERVICES_TYPES.PARKING) {
        service.places = dataZone[0].places;
        service.depassement = location.state.depassement;
      }

      if (service.idServiceType === SERVICES_TYPES.TAXE_SEJOUR) {
        hasTax.current = true;
        if (tarif.idUniteType === UNITE_TYPES.PERSONNE_24H) {
          nbPersonne.current = 2;
        }
      }

      // gestion d'un type maitre pour le groupage des services
      service.serviceTypeMaitre = service.serviceParent ? service.serviceParent.idServiceType : service.idServiceType;
    });

    // Regroupement des services par serviceType
    let groupedData = _.groupBy(services, "serviceTypeMaitre");
    let groupedArray = [];

    Object.keys(groupedData).forEach(key => groupedArray.push({
      id: key,
      name: groupedData[key][0].serviceType,
      services: groupedData[key]
    }));

    // Mise à jour des données
    setData(groupedArray);
  }, [dataZone, dataServices, dataTarifs, location.state]);

  // Retourne si une catégorie doit être affiché ou non
  const isCategorieVisible = (categorie, parent) => {
    let result = true;

    // Si mode enfant et qu'il n'y a pas de parent sélectionné, on cache
    if (mode === MODES.ENFANT && !parent) {
      result = false;
    }

    // Si mode total et qu'il n'y a pas de service sélectionné, on cache
    if (mode === MODES.TOTAL) {
      result = categorie.services.find(service => service.selected);;
    }

    return result;
  }

  // Sauvegarde de l'achat dans le store
  const storeAchat = useCallback((newAchat, isRachat) => {
    // Calcul de la validité de l'idAchat
    // // Récupération de la valeur max de quantite
    // let max = _.maxBy(detailsAchat.current, detail => detail.quantite * detail.secondes)

    // // Calcul de la date de validité
    // let dateValidite = location.state.dateValidite
    //   ?
    //   moment(location.state.dateValidite).add(max.quantite * max.secondes + 20, "second").format()
    //   :
    //   moment().add(max.quantite * max.secondes + 20, "second").format();

    // let dateDecalage = location.state.dateValidite
    //   ?
    //   moment(location.state.dateValidite)
    //   :
    //   moment();

    // Sauvegarde de l'idAchat dans le store
    dispatch(setAchat({
      codeAcces: newAchat.CodeAcces,
      // date: dateValidite,
      id: newAchat.idAchat,
      idSite: newAchat.idSite,
      immatriculation: newAchat.immatriculation,
      presence: false,
      rachat: isRachat,
      terminalNom: borne.name,
      // decalage: decalage.current ? dateDecalage : null
    }));
  }, [dispatch, borne]);
  // #endregion

  // #region REQUEST
  // Requête de récupération des services
  const requestGetServices = useJsonRequest({
    command: GET,
    getMode: GET_LIST_WITH_PARAMS,
    resource: RESSOURCE_SERVICES,
    url: URL_BASE,
  });

  // Requête de récupération des tarifs
  const requestGetTarifs = useJsonRequest({
    command: GET,
    getMode: GET_LIST_WITH_PARAMS,
    resource: RESSOURCE_TARIFS,
    url: URL_BASE,
  });

  // Requête de récupération des informations du terminal
  const requestGetZone = useJsonRequest({
    command: GET,
    getMode: GET_LIST_WITH_PARAMS,
    resource: RESSOURCE_ZONE,
    url: URL_BASE,
  });

  // Requête de création d'un achat
  const requestPostAchat = useJsonRequest({
    command: POST,
    resource: RESSOURCE_ACHATS,
    url: URL_BASE,
  });


  // Envoi de l'achat au serveur
  const doPostAchat = (achat, typePaiement = "Payzen") => {
    // Création de l'achat transmis à l'API Urbaflux
    let now = moment().format();
    let newAchat = {
      adresseMail: null,
      autorisationPayzen: null,
      CodeAcces: location.state.codeAcces ?? null,
      dateAchat: now,
      dateCreation: now,
      detailAchats: getServicesSelected(),
      idSite: borne.id,
      immatriculation: immatriculation,
      modePaiement: (borne.zones[0].paiementProd === 0 ? TYPE_ACHAT.TEST : TYPE_ACHAT.PROD) || TYPE_ACHAT.INCONNU,
      montant: parseFloat((montantTotal / 100).toFixed(2)),
      numeroCarte: null,
      presenceUser: 0,
      transactionIdPayzen: null,
      typePaiement: typePaiement,
      statusPayzen: null
    };

    // Si paiement payzen, on met à jour les champs avec les valeurs récupérées dans PaymentForm
    if (typePaiement === "Payzen") {
      newAchat = { ...newAchat, ...achat }
    }

    requestPostAchat(newAchat);
  };
  // #endregion

  // #region EVENTS
  // Gestion de la modification d'un service
  const handleChange = (categorie, index, haveParkingService) => {
    let d = [...data];
    d[index] = categorie;

    hasParking.current = haveParkingService;

    setData(d);
  };

  // Gestion du retour en arrière
  const handleClickAnnuler = () => {
    setMode(MODES.PARENT);
  };

  // Choix du mode de paiement
  const handleClickChoixModePaiement = (type) => {
    // Fermeture de la modale de choix de paiement
    setModaleChoixPaiementVisible(false);

    switch (type) {
      // Choix PAYZEN
      case PAYZEN:
        // Ouverture de la modale de paiement PAYZEN
        setModalePayzenVisible(true);
        break;

      // CHOIX TPE
      case PAYZEN_TPE:
        // Post de l'achat
        doPostAchat(null, TPE);
        break;

      default:
        break;
    }
  };

  // Click sur le bouton Payer
  const handleClickPay = (checkImmaticulation) => {
    // let isParking = detailsAchat.current.filter(item => item.serviceType === 4 || item.serviceType === 9).length > 0;
    // Si service de stationnement acheté, gestion de l'immatriculaton
    if (checkImmaticulation && hasParking.current) {
      if (immatriculation) {
        setModaleImmatriculation(true);
      } else {
        setMessageImmatriculation(true);
      }
      return;
    }

    // Choix du mode de paiment en fonction du type de borne
    switch (borne.idModePaiement) {
      case PAYZEN:
        setModalePayzenVisible(true);
        break;

      case PAYZEN_TPE:
        setModaleChoixPaiementVisible(true);
        break;

      default:
        break;
    }
  };

  // Gestion du clic de validation de la sélection
  const handleClickValid = () => {
    let newMode = null;
    // Vérification d'au moins un service acheté
    if (!montantTotal) {
      showError("Merci de sélectionner au moins un service");
      return;
    }

    // On passe au mode suivant
    switch (mode) {
      case MODES.PARENT:
        // Si on a un service enfant dans l'un des services sélectionné, alors on affiche le mode enfant
        let hasServiceEnfantSelectable = false;
        data.forEach(categorie => {
          const test = categorie.services.find((item) => { return !item.idServiceParent && item.selected && item.idServiceEnfants.length > 0 });
          if (test) {
            hasServiceEnfantSelectable = true;
          }
        });

        // Si un service sélectionné a au moin un enfant, on l'affiche, sinon on affiche le total
        newMode = hasServiceEnfantSelectable ? MODES.ENFANT : MODES.TOTAL;
        break;

      case MODES.ENFANT:
        newMode = MODES.TOTAL;
        break;
      default:
        break;
    }

    // Changement de mode
    setMode(newMode);
  };

  // Fermeture de la modale d'attente du paiement TPE
  const handleCloseModaleAttentePaiementTPE = (achat) => {
    // Fermeture de la modale
    setModaleAttentePaiementTPEVisible(false);

    if (!achat) {
      return;
    }

    switch (achat.statusPayzen) {
      case PAID:
        // Sauvegarde de l'achat dans le store
        storeAchat(achat, location.state.rachat);

        // Ouverture de la modale
        setTicketClientModaleVisible(true);
        break;

      default:
        // Information à l'utilisateur
        showError("Le paiement n'a pas abouti");
        break;
    }
  };

  // Paiement ko, sauvegarde de l'idAchat
  const handlePaymentError = (newAchat) => {
    doPostAchat(newAchat);
  };

  // Paiement ok
  const handlePaymentSuccess = (newAchat) => {
    // Désactivation de la modale
    setModalePayzenVisible(false);

    //  Envoi de l'idAchat au serveur
    doPostAchat(newAchat);

    // Sauvegarde de l'achat pour création du  ticket
    currentAchat.current = {
      ...newAchat,
      type: PAYZEN
    };

    // Ouverture de la modale du ticket
    setTicketClientModaleVisible(true);
  };
  // #endregion

  // #region HOOK D'EFFET
  // Gestion du bouton back du header
  useEffect(() => {
    dispatch(setBackVisible(true));

    return () => dispatch(setBackVisible(false));
  }, [dispatch]);

  // Lancement des requêtes pour récupérer les données
  useEffect(() => {
    if (!borne || !token) {
      return;
    }

    let params = [`idSite=${borne.id}`];
    requestGetServices(params);
    requestGetTarifs(params);

    let zoneParams = [`idTerminal=${borne.id}`];
    requestGetZone(zoneParams);
  }, [borne, token, requestGetServices, requestGetTarifs, requestGetZone]);

  // Récupération des services
  useEffect(() => {
    if (!dataReceivedServices) {
      return;
    }

    let d = [...dataReceivedServices];

    // Récupération du libellé
    d.forEach(service => {
      let serviceType = serviceTypes.data.find(type => type.idServiceType === service.idServiceType);
      service["serviceType"] = serviceType.libelle;
    });
    setDataServices(d);

  }, [dataReceivedServices, serviceTypes.data]);

  // Récupération des tarifs
  useEffect(() => {
    if (!dataReceivedTarifs) {
      return;
    }

    let d = [...dataReceivedTarifs];

    // Enregistrement de la devise du terminal
    devise.current = d[0].devise;

    // Récupération du libellé de l'unité type
    d.forEach(tarif => {
      let uniteType = uniteTypes.data.find(type => type.idUniteType === tarif.idUniteType);
      tarif["libelleType"] = uniteType.libelleType;
      tarif["secondes"] = uniteType.quantite;
    });

    setDataTarifs(d);
  }, [dataReceivedTarifs, uniteTypes.data]);

  // Récupération des informations sur la zone
  useEffect(() => {
    if (!dataReceivedZone) {
      return;
    }

    let d = [...dataReceivedZone];

    // Calcul du nombre de place disponibles
    const places = d[0].nbPlace - d[0].placeOccupe;
    d.map((item) => { return item.places = places });

    // Initialisation du state
    setDataZone(d);
  }, [dataReceivedZone]);

  // Initialisation des données
  useEffect(() => {
    if (!dataZone || !dataTarifs || !dataServices) {
      return;
    }

    initializeData();
  }, [dataZone, dataServices, dataTarifs, initializeData]);

  // Mise à jour du montant total
  useEffect(() => {
    if (!data) {
      return;
    }

    // Boucle dans les catégories + services incrémenter le montant
    const calculMontantTotal = () => {
      let total = 0;

      // Boucle
      data.forEach(categorie => {
        categorie.services.forEach(service => {
          if (!service.montant) {
            return;
          }

          // Incrémentation du total
          total = total + service.montant;
        });
      });

      // Mise à jour des données
      setMontantTotal(total);
    };

    calculMontantTotal();
  }, [data]);

  // Réception de la confirmation de la création de l'achat
  useEffect(() => {
    if (!dataUpdatedAchats) {
      return;
    }

    // Gestion du retour d'un achat TPE en attente
    if (dataUpdatedAchats.typePaiement === TPE) {
      // Enregistrement des informations necessaires à la création du ticket client
      typePaiement.current = dataUpdatedAchats.typePaiement;
      achatTPEId.current = dataUpdatedAchats.idAchat;

      // Ouverture de la modale d'attente du paiement
      setModaleAttentePaiementTPEVisible(true);
      return;
    }

    // Gestion du retour d'un achat Payzen valide
    if (dataUpdatedAchats.statusPayzen !== PAID) {
      return;
    }

    // Sauvegarde de l'achat dans le store
    storeAchat(dataUpdatedAchats);
  }, [storeAchat, dataUpdatedAchats]);
  // #endregion

  // #region INTERFACE
  return (
    <div className="Achat-Container">
      <div className="Achat-TitleContainer">
        {`${t("achat.borne")} ${borne.name}`}
      </div>

      <PerfectScrollbar>
        <List>
          {/* Description */}
          {dataZone
            ? <DescriptifBorne data={dataZone[0]} />
            : null
          }
          {/* Immatriculation */}
          {mode === MODES.TOTAL && hasParking.current ?
            <div className="Achat-TextField">
              <TextField
                label={t("achat.immatriculation")}
                name="immatriculation"
                onChange={(e) => setImmatriculation(e.target.value)}
                outlined
                required
                value={immatriculation}
              />
            </div>
            :
            null
          }

          {/* Liste des catégories */}
          {data && data.map((categorie, index) => {
            const serviceParentSelected = categorie.services.find((item) => { return !item.idServiceParent && item.selected });
            return isCategorieVisible(categorie, serviceParentSelected) ? (
              <AchatList
                categorie={categorie}
                categorieIndex={index}
                mode={mode}
                onChange={handleChange}
                parentSelected={serviceParentSelected}
              />
            )
              :
              null
          })}
        </List>
      </PerfectScrollbar>
      <div className="Achat-ButtonContainer">
        {/* Retour arrière */}
        {mode !== MODES.PARENT
          ?
          <Fab
            icon="undo"
            label={t("achat.cancel")}
            onClick={handleClickAnnuler}
            style={{ marginRight: "10px" }}
          />
          :
          null
        }
        {/* Valider */}
        <Fab
          icon={getButtonIcon()}
          label={getButtonLabel()}
          onClick={() => { mode === MODES.TOTAL ? handleClickPay(true) : handleClickValid() }}
        />
      </div>

      {/* Modales */}
      <ConfirmModal
        visible={modaleImmatriculation}
        onClose={() => setModaleImmatriculation(false)}
        onValid={() => [setModaleImmatriculation(false), handleClickPay(false)]}
        title={t("achat.confirmTitle")}
        children={t("achat.confirmMessage")}
        immatriculation={immatriculation}
        height={"250px"}
      />
      <Snackbar
        open={messageImmatriculation}
        onClose={() => setMessageImmatriculation(false)}
        message={t("achat.needImmatriculation")}
        dismissesOnAction
        action={
          <SnackbarAction
            label={t("achat.hideMessage")}
            onClick={() => setMessageImmatriculation(false)}
          />
        }
      />

      {devise.current
        ?
        <Modale
          onClose={() => { setModalePayzenVisible(false) }}
          title="Informations bancaires"
          visible={modalePayzenVisible}
          width="350px"
        >
          <PaymentForm
            borne={borne}
            devise={devise.current}
            montant={montantTotal * 100}
            onErrorPaid={(achat) => { handlePaymentError(achat) }}
            onSuccessPaid={(achat) => { handlePaymentSuccess(achat) }}
          />
        </Modale>
        :
        null
      }
      <ModaleTypePaiement
        onClick={handleClickChoixModePaiement}
        onClose={() => setModaleChoixPaiementVisible(false)}
        visible={modaleChoixPaiementVisible}
      />

      <TicketClientModale
        achat={currentAchat.current}
        idAchatTPE={achatTPEId.current}
        onClose={() => { setTicketClientModaleVisible(false) }}
        typePaiement={typePaiement.current}
        visible={modaleTicketPayzenVisible}
      />
      <ModaleAttentePaiementTPE
        idAchat={achatTPEId.current}
        onClose={handleCloseModaleAttentePaiementTPE}
        visible={modaleAttentePaiementTPEVisible}
      />
    </div>
  );
  // #endregion
};
// #endregion

export { Achat };

