import { Fab } from "@rmwc/fab";
import { TextField } from "@rmwc/textfield";
import { HmacSHA256 } from "crypto-js";
import Base64 from 'crypto-js/enc-base64';
import { t } from "i18next";
import moment from "moment";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { ConfirmModal } from "Shared/ConfirmModal";

import "./PaymentForm.css";

// #region CONSTANTES
const AUTHORISED = "AUTHORISED"
const TEST = "TEST"
const PROD = "PRODUCTION"
// #endregion

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher le formulaire de paiement
 * @category PaymentForm
 */
function PaymentForm(props) {
  // #region INITIALISATION
  // Récupération des props
  const {
    borne,
    devise,
    montant,
    onErrorPaid,
    onSuccessPaid
  } = props;

  // Initialisation du state
  const [mailInfo, setMailInfo] = useState(""); // Mail de l'acheteur en cours
  const [mailValidate, setMailValidate] = useState(false); // True si le mail a été validé
  const [showErrorMessage, setShowErrorMessage] = useState(false); // Affiche un message d'erreur si l'adresse mail est incorrecte
  const [showMessageErrorPaiement, setShowMessageErrorPaiement] = useState(false); // Gestion du message d'erreur
  const [modaleConfirmMail, setModaleConfirmMail] = useState(false);

  // Initialisation des références
  const signature = useRef("");
  const dateMoment = useRef(moment().format("YYYYMMDDHHmmss"));
  //#endregion

  //#region UTILS
  // Renvoie la devise en string
  const getDevise = (value) => {
    switch (value) {
      // EUR //
      // Payzen
      case "978":
        return "EUR"

      // Urbaflux
      case "EUR":
        return "978"

      default:
        break;
    }
  };

  // Récupération des informations de Payzen
  const getPayzenInfos = (data) => {
    let obj = {}
    var dataToSplit = data;
    var qArray = dataToSplit.split('&');

    // Ajout des champs dans un objet
    for (var i = 0; i < qArray.length; i++) {
      let pArr = qArray[i].split('=');
      obj[pArr[0]] = pArr[1];
    }

    let payzenInfos = {
      adresseMail: obj.vads_cust_email.replace("%40", "@"),
      autorisationPayzen: obj.vads_auth_number,
      numeroCarte: obj.vads_card_number,
      devise: getDevise(obj.vads_currency),
      montant: parseFloat((obj.vads_effective_amount / 100).toFixed(2)),
      modePaiement: obj.vads_ctx_mode,
      operationType: obj.vads_operation_type,
      transactionIdPayzen: obj.vads_trans_uuid,
      statusPayzen: obj.vads_auth_result,
      shopId: obj.vads_site_id,
    };

    if (obj.vads_trans_status === AUTHORISED) {
      onSuccessPaid(payzenInfos);
    } else {
      setShowMessageErrorPaiement(true);
      onErrorPaid(payzenInfos);
    }
  }

  // Paramètres de la requête Payzen
  let PARAMETRES = {
    action_mode: "IFRAME",
    amount: montant.toString(),
    captureDelay: "0",
    ctxMode: borne.zones.filter(zone => zone.idZone === 1)[0].paiementProd === 0 ? TEST : PROD,
    currency: getDevise(devise),
    email: mailInfo,
    info: borne.name,
    pageAction: "PAYMENT",
    paymentCard: "CB;VISA;MASTERCARD",
    paymentConfig: "SINGLE",
    redirectErrorTimeout: 0,
    redirectSuccessTimeout: 0,
    returnMode: "GET",
    siteId: borne.zones[0].BnqSiteId,
    themeConfig: "3DS_LOGOS=false;FORM_TARGET=_self",
    transDate: dateMoment.current,
    // transId: dateMoment.current.slice(8),
    transId: dateMoment.current.slice(8),
    urlReturn: window.location.origin + "/paiement",
    urlSuccess: window.location.origin + "/paiement",
    version: "V2",
    vadsCertificate: borne.zones[0].BnqKey
  };

  // Vérifie si le mail est valide
  const isValidateEmail = () => {
    let value = /\S+@\S+\.\S+/.test(mailInfo);

    if (!value) {
      setShowErrorMessage(true);
    };

    return value;
  };
  // #endregion

  //#region EVENTS
  // Récupération des informations envoyées par le composant Paiement
  window.onmessage = function (e) {
    if (e.data.text === 'payzen infos') {
      getPayzenInfos(e.data.payzenInfos)
    }
  };
  //#endregion

  // #region HOOK D'EFFET
  // Initialisation de la signature du formulaire
  useEffect(() => {
    if (!montant || !isValidateEmail()) {
      return;
    }

    // Signature a encoder
    let signatureString = "";

    // Concaténation des champs pour la création de la signature à encoder
    Object.values(PARAMETRES).forEach(val => signatureString = signatureString.concat("+", val));

    // Enregistrement de la signature en base64
    signature.current = Base64.stringify(HmacSHA256(signatureString.substring(1), PARAMETRES.vadsCertificate))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [borne, montant, dateMoment, mailInfo]);
  // #endregion

  // #region INTERFACE
  return (
    !showMessageErrorPaiement
      ?
      <div className="container">
        <div>
          <iframe
            title="form"
            id="urbaflux-iframe"
            name="formUrbaflux"
            src={"https://secure.payzen.eu/vads-payment/"}
            width={mailValidate ? 340 : 0}
            height={mailValidate ? 380 : 0}
            style={{ "visibility": mailValidate ? "visible" : "hidden" }}
          // scrolling="no"
          />
          <form id="form-urbaflux" method="POST" target="formUrbaflux" action="https://secure.payzen.eu/vads-payment/" >
            <input type="hidden" name="vads_action_mode" value={PARAMETRES.action_mode} />
            <input type="hidden" name="vads_amount" value={montant.toString()} />
            <input type="hidden" name="vads_capture_delay" value={PARAMETRES.captureDelay} />
            <input type="hidden" name="vads_ctx_mode" value={PARAMETRES.ctxMode} />
            <input type="hidden" name="vads_currency" value={PARAMETRES.currency} />
            <input type="hidden" name="vads_cust_email" value={PARAMETRES.email} />
            <input type="hidden" name="vads_order_info" value={PARAMETRES.info} />
            <input type="hidden" name="vads_page_action" value={PARAMETRES.pageAction} />
            <input type="hidden" name="vads_payment_cards" value={PARAMETRES.paymentCard} />
            <input type="hidden" name="vads_payment_config" value={PARAMETRES.paymentConfig} />
            <input type="hidden" name="vads_redirect_error_timeout" value={PARAMETRES.redirectErrorTimeout} />
            <input type="hidden" name="vads_redirect_success_timeout" value={PARAMETRES.redirectSuccessTimeout} />
            <input type="hidden" name="vads_return_mode" value={PARAMETRES.returnMode} />
            <input type="hidden" name="vads_site_id" value={PARAMETRES.siteId} />
            <input type="hidden" name="vads_theme_config" value={PARAMETRES.themeConfig} />
            <input type="hidden" name="vads_trans_date" value={PARAMETRES.transDate} />
            <input type="hidden" name="vads_trans_id" value={PARAMETRES.transId} />
            <input type="hidden" name="vads_url_return" value={PARAMETRES.urlReturn} />
            <input type="hidden" name="vads_url_success" value={PARAMETRES.urlSuccess} />
            <input type="hidden" name="vads_version" value={PARAMETRES.version} />
            <input type="hidden" name="signature" value={signature.current} />
          </form>
        </div>

        <div className="container" style={{ visibility: !mailValidate ? "visible" : "hidden" }}>
          <div className="textContainer">
            <p className="textTitle" >Veuillez renseigner votre adresse mail pour l'envoi du ticket</p>
            <TextField
              label={t("achat.email")}
              name="immatriculation"
              onChange={(e) => [setMailInfo(e.target.value), setShowErrorMessage(false)]}
              outlined
              required
              type="email"
              value={mailInfo}
            />
            {showErrorMessage
              ?
              <p className="textError">Veuillez renseigner une adresse mail correcte pour valider</p>
              :
              null
            }
          </div>
          < Fab
            id="urbaflux-iframe"
            form="form-urbaflux"
            type="submit"
            label={`${t("achat.validation")}`}
            onClick={() => isValidateEmail() ? setModaleConfirmMail(true) : null}
          />
        </div>
        <ConfirmModal
          visible={modaleConfirmMail}
          onClose={() => setModaleConfirmMail(false)}
          onValid={() => [setModaleConfirmMail(false), setMailValidate(true)]}
          title={t("achat.confirmTitleMail")}
          children={t("achat.confirmMessageMail")}
          info={mailInfo}
          height={"250px"}
        />
      </div>
      :
      <div className="containerError">
        <p className="textTitle">
          {t("achat.error")}
          <br />
          {t("achat.error2")}
        </p>
        <Fab
          label="Nouvel essai"
          onClick={() => window.location.reload()}
        />
      </div>
  );
  // #endregion
};
// #endregion

// #region PROPRIETES
/**
 * Type des propriétés de {@link PaymentForm}
 * @typedef {Object} PaymentForm.propTypes
 * @property {object} borne Terminal selectionné
 * @property {string} [devise=null] Devise au format ISO 4217
 * @property {number} montant Montant de la transaction
 * @property {function} onErrorPaid Fonction callback sur erreur du paiement
 * @property {function} onSuccessPaid Fonction callback au succès du paiement
*/
PaymentForm.propTypes = {
  borne: PropTypes.object.isRequired,
  devise: PropTypes.string,
  montant: PropTypes.number.isRequired,
  onErrorPaid: PropTypes.func.isRequired,
  onSuccessPaid: PropTypes.func.isRequired
};

// Valeurs par défault des propriétés
PaymentForm.defaultProps = {
  devise: null
};
// #endregion

export { PaymentForm };

