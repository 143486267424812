import car from "Resources/aire-de-vidange-camping-car.svg";
import parking from "Resources/parking-sign.svg";

export const selectIcon = (service) => {
  switch (service) {
    case 1:
    case "1":
      return car

    case 2:
    case 3:
    case 7:
    case "2":
    case "3":
    case "7":
      return "bolt"
    case 4:
    case "4":
      return parking
    default:
      return "info"
  }
};
