import { Fab } from "@rmwc/fab";
import { TextField } from "@rmwc/textfield";
import { setToken, setTokenAdmin } from "App/Authentification/Store/actions";
import { selectTokenAdmin } from "App/Authentification/Store/selectors";
import { TAB_MAP, URL_BASE } from "App/Constants";
import { showError } from "App/Toast/Toast";
import { APP_LOGIN_ISIPAY as loginIsipay, APP_MDP_ISIPAY as mdpIsipay } from "Config.json";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { POST } from "Request/Components/RequestUtils";
import { GET_LIST_WITH_PARAMS, useJsonRequest } from "Request/Components/useJsonRequest";
import { selectDataResourceReceived, selectDataResourceUpdated } from "Request/Store/selectors";
import logoIsipay from "Resources/ISIPAY_LOGO.png";
import { RESSOURCE_AUTHENTIFICATION, RESSOURCE_FORGOT_PASSWORD, URL } from "../API/LoginAPI";
import "./Login.css";

// #region FONCTION COMPOSANT
/**
* Composant permettant de se connecter à l'application
* @class Account
* @category Login
*/
function Login() {
  // #region INITIALISATION
  // Initialisation du state
  const [canLogin, setCanLogin] = useState(true);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [login, setLogin] = useState(process.env.NODE_ENV === "production" ? "" : process.env.REACT_APP_LOGIN_DEV);
  const [password, setPassword] = useState(process.env.NODE_ENV === "production" ? "" : process.env.REACT_APP_MDP_DEV);

  // Initialisation des selecteurs
  const dataReceivedToken = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_AUTHENTIFICATION)); // Retour de la requête de connexion
  const dataUpdatedForgetPassword = useSelector((state) => selectDataResourceUpdated(state, RESSOURCE_FORGOT_PASSWORD)); // Retour de la requête du mot de passe oublié
  const tokenAdmin = useSelector((state) => selectTokenAdmin(state));

  // Traduction i18n
  const { t } = useTranslation();

  // Initialisation du dispatch
  const dispatch = useDispatch();

  // Récupération de l'historique
  const history = useHistory();
  const location = useLocation();
  // #endregion

  // #region UTILS
  const validateEmail = (email) => {
    var str = /\S+@\S+\.\S+/;
    return str.test(email);
  }

  const isValidPassword = () => {
    let isValid = false;
    if (!login || !password) {
      showError(t("login.loginPasswordRequired"));
      return isValid;
    }

    if (password.length < 2) {
      showError(t("login.wrongPassword"));
      return isValid;
    }

    isValid = true;
    return isValid;
  };
  // #endregion

  // #region REQUEST
  // Requête de récupération du token
  const requestGetToken = useJsonRequest({
    command: POST,
    getMode: GET_LIST_WITH_PARAMS,
    resource: RESSOURCE_AUTHENTIFICATION,
    silent: true,
    tokenObligatory: false,
    tokenAdminObligatory: true,
    url: URL
  });

  const requestGetTokenAdmin = useJsonRequest({
    command: POST,
    getMode: GET_LIST_WITH_PARAMS,
    resource: RESSOURCE_AUTHENTIFICATION,
    tokenObligatory: false,
    silent: true,
    url: URL
  });

  // Requête d'envoi du mail
  const requestPostMail = useJsonRequest({
    command: POST,
    resource: RESSOURCE_FORGOT_PASSWORD,
    url: URL_BASE,
  });
  // #endregion

  // #region EVENTS
  // Envoi des données de connexion au serveur
  const handleSubmitMail = () => {
    validateEmail(login)

    requestPostMail({ mail: login });
  };

  // Envoi des données de connexion au serveur
  const handleSubmitLogin = () => {
    if (!isValidPassword()) {
      return;
    }

    let params = {
      username: login,
      password: password
    };

    requestGetToken(params);
  };
  // #endregion

  // #region HOOK D'EFFET
  // Récupération et gestion du token ISIPAY
  useEffect(() => {
    if (tokenAdmin && tokenAdmin.tokenAdmin) {
      return;
    }

    let params = {
      username: loginIsipay,
      password: mdpIsipay,
    };

    setCanLogin(false);
    requestGetTokenAdmin(params);
  }, [tokenAdmin, requestGetTokenAdmin]);

  // Enregistrement du token et navigation
  useEffect(() => {
    if (!dataReceivedToken) {
      return;
    }

    // Enregistrement du token admin
    if (!tokenAdmin.tokenAdmin) {
      dispatch(setTokenAdmin(dataReceivedToken.token));
      setTimeout(() => {
        setCanLogin(true);
      }, 1500);
      return;
    }

    if (!canLogin) {
      return;
    }

    // Enregistrement du token classique
    dispatch(setToken(dataReceivedToken.token));
    // Ajout des paramètres de la map et redirection
    const mapParameters = location.state ? location.state.mapParams : "";
    history.push("/" + mapParameters, { selectedIndexTab: TAB_MAP });
  }, [canLogin, dispatch, dataReceivedToken, history, location, tokenAdmin]);

  // Retour à l'écran de connexion après l'envoi de l'adresse mail
  useEffect(() => {
    if (!dataUpdatedForgetPassword) {
      return;
    }

    setIsForgotPassword(false);
  }, [dataUpdatedForgetPassword]);
  // #endregion

  // #region INTERFACE
  // Interface d'oubli de mot de passe
  const forgotPasswordInterface = () => (
    <>
      <div className="Login-Title">
        <h1>Mot de passe Oublié</h1>
      </div>
      <TextField
        label={t("login.mail")}
        name="mail"
        onChange={(item) => setLogin(item.target.value)}
        outlined
        required
        value={login}
      />
      <div className="Login-Button-Container">
        <Fab
          label={t("login.validation")}
          onClick={() => handleSubmitMail()}
        />
        <href
          className="Login-Link"
          onClick={() => setIsForgotPassword(false)}
        >
          {t("login.backToLogin")}
        </href>
      </div>
    </>
  );


  // Interface de connexion
  const loginInterface = () => (
    <>
      <TextField
        label={t("login.mail")}
        name="login"
        onChange={(item) => setLogin(item.target.value)}
        outlined
        required
        value={login}
      />
      <TextField
        label={t("login.password")}
        name="password"
        onChange={(item) => setPassword(item.target.value)}
        outlined
        required
        type="password"
        value={password}
      />
      <div className="Login-Button-Container">
        <Fab
          label={t("login.connection")}
          onClick={() => handleSubmitLogin()}
        />
        <p className="Login-Link" onClick={() => setIsForgotPassword(true)}>
          {t("login.forgotPassword")}
        </p>
        <p className="Login-Link" onClick={() => history.push("/nouveauCompte")} >
          {t("login.accountTitleCreate")}
        </p>
      </div>
    </>
  );

  return (
    <div className="Login-Container" >
      <img
        alt="logo ISIPAY"
        className="Login-Logo"
        src={logoIsipay}
      />
      {isForgotPassword
        ?
        forgotPasswordInterface()
        :
        loginInterface()
      }
    </div>
  );
  // #endregion
};
// #endregion

export { Login };

