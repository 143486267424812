import { DistributionListItem } from "./DistributionListItem";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CollapsibleList, SimpleListItem, } from "@rmwc/list";
import car from "Resources/aire-de-vidange-camping-car.svg";
import parking from "Resources/parking-sign.svg";
import '@rmwc/list/styles';
import "./DistributionList.css";

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher la liste pour achat sur une borne
 * @category Distribution
 * @param {DistributionList.propTypes} props Les propriétés du composant
 */
function DistributionList(props) {
  //#region INITIALISATION
  // Récupération des props
  const {
    categorie,
    handleRachat
  } = props;

  const [data, setData] = useState([]);
  // #endregion

  // #region EVENTS
  const initializeData = () => {
    const cloneData = _.cloneDeep(categorie.detailAchats)
    const dataGroup = _.groupBy(cloneData, "idService");
    const keys = Object.keys(dataGroup);

    let newArray = []

    keys.forEach(key => {
      const lastAchat = dataGroup[key].slice(-1)[0];

      newArray.push(lastAchat);
    });
    setData(newArray);
  };

  const selectIcon = (service) => {
    switch (service) {
      case "1":
        return car;
      case "2":
        return "bolt";
      case "4":
        return parking;
      default:
        return "info";
    }
  }
  // #endregion

  //#region HOOK D'EFFET
  useEffect(() => {
    setTimeout(() => {
      initializeData();
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion

  //#region INTERFACE
  return (
    <CollapsibleList
      key={categorie.id}
      defaultOpen
      handle={
        <SimpleListItem
          className="DistributionList-HeaderList"
          graphic={selectIcon(categorie.id)}
          metaIcon="chevron_right"
          text={categorie.name}
        />
      }
    >
      {data.map((item) => {
        return (
          <DistributionListItem
            key={item.idDetailAchat}
            detailAchat={item}
            handleRachat={handleRachat}
          />
        )
      })}
    </CollapsibleList>
  );
  // #endregion
};
// #endregion

// #region PROPRIETES
/**
 * Type des propriétés de {@link DistributionList}, hérite des propriétés de {@link DistributionListItem}
 * @typedef {Object} DistributionList.propTypes
 * @property {object} categorie La liste des catégories des items de la borne
 */
DistributionList.propTypes = {
  categorie: PropTypes.object.isRequired
};
// #endregion

export { DistributionList };
