import * as Constants from "./constants";

/**
 * Reducer de gestion du token dans le store
 * @private
 * @param {object} state Le state de l'application
 * @param {object} action L'action contenant le type et la valeur
 */
function manageToken(state = Constants.INITIAL_STATE, action) {
  let nextState;
  switch (action.type) {
    case Constants.SET_TOKEN:
      // Mise à jour du token
      nextState = {
        ...state,
        token: {
          ...state.token,
          token: action.value,
          date: new Date(Date.now())
        }
      };
      return nextState || state;

    case Constants.CLEAR_TOKEN:
      // Mise à jour du token
      nextState = {
        token: {
          token: null,
          date: null
        }
      };
      return nextState || state;

    case Constants.SET_TOKEN_ADMIN:
      // Mise à jour du token
      nextState = {
        ...state,
        tokenAdmin: {
          ...state.tokenAdmin,
          tokenAdmin: action.value,
          date: new Date(Date.now())
        }
      };
      return nextState || state;

    case Constants.CLEAR_TOKEN_ADMIN:
      // Mise à jour du token
      nextState = {
        tokenAdmin: {
          tokenAdmin: null,
          date: null
        }
      };
      return nextState || state;

    default:
      return state;
  }
}

/**
 * Reducer de gestion du user dans le store
 * @private
 * @param {object} state Le state de l'application
 * @param {object} action L'action contenant le type et la valeur
 */
function manageUser(state = Constants.INITIAL_STATE, action) {
  let nextState;
  switch (action.type) {
    case Constants.SET_USER:
      // Mise à jour du token
      nextState = {
        ...state,
        token: {
          ...state.token,
          id: action.value,
        }
      };
      return nextState || state;

    default:
      return state;
  }
}

export { manageToken, manageUser };

