import { Tab, TabBar } from "@rmwc/tabs";
import '@rmwc/tabs/styles';
import { Theme } from "@rmwc/theme";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./BottomNavBar.css";

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher la barre de navigation
 * @category BottomNavBar
 * @param {BottomNavBar.propTypes} props Les propriétés du composant
 */
function BottomNavBar(props) {
  //#region INITIALISATION
  // Récupération des props
  const {
    activeTabIndex,
    onChangeActiveTab
  } = props;
  // Traduction i18n
  const { t } = useTranslation();

  // #endregion

  // #region INTERFACE
  return (
    <div className="BottomNavBar-Container">
      <Theme use={['onSecondary']} wrap>
        <TabBar
          activeTabIndex={activeTabIndex}
          className="TabBar"
          onActivate={(evt) => onChangeActiveTab(evt.detail.index)}
        >
          <Tab
            icon="explore"
            style={{ color: "#FFF" }}
            label={t("accueil.map")}
            stacked
          />
          <Tab
            icon="update"
            label={t("accueil.distribution")}
            stacked
          />
        </TabBar>
      </Theme>
    </div >
  );
  // #endregion
};
// #endregion

// #region PROPRIETES
/**
 * Type des propriétés de {@link BottomNavBar}
 * @typedef {Object} BottomNavBar.propTypes
 * @property {number} activeTabIndex Indice de la TabBar sélectionnée
 * @property {function} onChangeActiveTab Fonction de callback lors de la sélection d'une TabBar
 */
BottomNavBar.propTypes = {
  activeTabIndex: PropTypes.number.isRequired,
  onChangeActiveTab: PropTypes.func.isRequired
};
// #endregion

export { BottomNavBar };
