import { BlobProvider } from "@react-pdf/renderer";
import { Fab } from "@rmwc/fab";
import { RESSOURCE_ACHATS } from "Achat/API/AchatApi";
import { selectAchat } from "Achat/Store/selectors";
import { URL_BASE } from "App/Constants";
import { Modale } from "App/Modale/Components/Modale";
import { PDFViewer } from "App/PDFViewer/PDFViewer";
import { showSuccess } from "App/Toast/Toast";
import moment from "moment";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GET } from "Request/Components/RequestUtils";
import { GET_LIST_WITH_PARAMS, GET_SINGLE, useJsonRequest } from "Request/Components/useJsonRequest";
import { selectDataResourceReceived } from "Request/Store/selectors";
import { RESSOURCE_TICKET_ACHATS } from "../API/TicketClientAPI";
import { TicketClient } from "./TicketClient";

import "@rmwc/circular-progress/styles";
import "@rmwc/fab/styles";
import "./TicketClientModale.css";

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher la modale contenant le ticket Client
 * @category Ticket Client
 */
function TicketClientModale(props) {
  //#region INITIALISATION
  const {
    achat,
    idAchatTPE,
    onClose,
    typePaiement,
    visible,
  } = props;

  // Traduction i18n
  const { t } = useTranslation();

  // Récupération de l'historique
  const history = useHistory();

  // Initialisation des selecteurs
  const achats = useSelector((state) => selectAchat(state)); // Achat stocké dans le store
  const dataReceived = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_TICKET_ACHATS)); // Données issues de la requête
  const dataReceivedAchat = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_ACHATS)); // Données issues de la requête

  // Initialisation du state
  const [dataTicketTPE, setDataTicketTPE] = useState(null);
  const [newAchat, setNewAchat] = useState({});
  // #endregion

  // #region REQUESTS
  // Requêtes des achats
  const requestGetAchats = useJsonRequest({
    command: GET,
    getMode: GET_LIST_WITH_PARAMS,
    resource: RESSOURCE_ACHATS,
    url: URL_BASE,
  });

  // Requête de récupération du ticket d'un achat TPE
  const requestGetTicketAchat = useJsonRequest({
    command: GET,
    getMode: GET_SINGLE,
    resource: RESSOURCE_TICKET_ACHATS,
    silent: true,
    url: URL_BASE,
  });
  // //#endregion

  // #region EVENTS
  // Fermeture de la modale
  const handleClose = () => {
    // Fermeture de la modale
    onClose();

    // Navigation vers l'écran de la distribution en cours
    history.push("/", {
      selectedIndexTab: 1
    });
  };
  // #endregion

  // #endregion HOOK D'EFFET
  // Test s'il s'agit d'un achat via TPE
  useEffect(() => {
    if (!idAchatTPE || typePaiement !== "TPE" || !visible) {
      return;
    }

    // Appel du ticket TPE
    requestGetTicketAchat(idAchatTPE);
  }, [requestGetTicketAchat, typePaiement, idAchatTPE, visible]);

  // Requête pour récupérer le digicode de l'achat créé
  useEffect(() => {
    if (!achats.length) {
      return;
    }

    //Récupération du dernier id d'achat
    const lastAchat = achats.slice(-1)[0];

    // Si pas d'immatriculation, on sort
    if (!lastAchat.immatriculation) {
      return;
    }
    // gestion du paramètre et envoi de la requête
    const params = [`idAchat=${lastAchat.id}`];
    setTimeout(() => {
      requestGetAchats(params);
    }, 2500);
  }, [achats, requestGetAchats])

  // Réception du ticket de l'achat TPE
  useEffect(() => {
    if (!dataReceived || !visible) {
      return;
    }

    setDataTicketTPE(dataReceived.ticketClient);
  }, [dataReceived, visible]);

  // Réception du dernier achat
  useEffect(() => {
    if (!dataReceivedAchat) {
      return;
    }

    setNewAchat(dataReceivedAchat[0]);
  }, [dataReceivedAchat])
  // #endregion

  // #region INTERFACE
  if (!achat && !idAchatTPE) {
    return null;
  }

  // Interface
  return (
    <Modale
      height="500px"
      title={t("ticket.title")}
      visible={visible}
      width="320px"
    >
      <div className="TicketClientModale-Container">
        {(typePaiement === "TPE" && dataTicketTPE) || achat ?
          <PDFViewer>
            <TicketClient
              achatPayzen={achat ?? dataTicketTPE}
              achatData={newAchat}
              typePaiement={typePaiement}
            />
          </PDFViewer>
          :
          null
        }
        <BlobProvider
          document={
            <TicketClient
              achatPayzen={achat ?? dataTicketTPE}
              achatData={newAchat}
              typePaiement={typePaiement}
            />
          }
        >
          {({ url }) => {
            if (!url) {
              return null;
            }

            return (
              <div className="TicketClientModale-ButtonContainer">
                <a href={url} target="_blank" rel="noreferrer" className="TicketClientModale-link" download={`ticket_Client_${moment(achat ? achat.date : Date.now()).local().format("YYYYMMDD_HHmmss")}.pdf`}>
                  <Fab
                    icon="download"
                    label={t("ticket.save")}
                    onClick={() => {
                      showSuccess("Le ticket a été téléchargé");
                      setTimeout(() => {
                        handleClose();
                      }, 1500);
                    }}
                  />
                </a>
              </div>
            )
          }}
        </BlobProvider>
      </div>
    </Modale >
  );
  // #endregion
};
// #endregion

// #region PROPRIETES
/**
 * Type des propriétés de {@link TicketClientModale}
      * @typedef {Object} TicketClientModale.propTypes
      * @property {object} [achat=null] Objet contenant l'achat effectué
      * @property {number} [idAchatTPE=null] Id de l'achat dans le cadre d'un paiement TPE
      * @property {function} onClose Fonction callback sur fermeture de la modale
      * @property {string} [typePaiement=null] Type de paiement
      * @property {boolean} visible True pour afficher la modale, false sinon
      */
TicketClientModale.propTypes = {
  achat: PropTypes.object,
  idAchatTPE: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  typePaiement: PropTypes.string,
  visible: PropTypes.bool.isRequired,
};

// Valeurs par défaut des propriétés
TicketClientModale.defaultProps = {
  achat: null,
  idAchatTPE: null,
  typePaiement: null
};
// #endregion

export { TicketClientModale };

