import { URL as URLLogin } from "Config.json";

// #region CONSTANTES
export const RESSOURCE_AUTHENTIFICATION = "authentication_token";
export const RESOURCE_ACCOUNT = "users"
export const RESOURCE_ACCOUNT_INFO = "users/informations"
export const RESSOURCE_LOGIN = "login";
export const RESSOURCE_PASSWORD = "users/password";
export const RESSOURCE_FORGOT_PASSWORD = "forgotPassword";
export const URL = URLLogin;
// #endregion
