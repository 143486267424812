import * as Constants from "./constants";

/**
 * Actions pour la gestion du token
 * @category Store
 * @hideconstructor
 */
class TokenStoreAction {
  /**
     * Nettoyage du token
     * @param {string} token Token
     */
  static clearToken(token) {
    return {
      type: Constants.CLEAR_TOKEN,
      value: token
    };
  }

  /**
   * Mise à jour du token
   * @param {string} token Token
   */
  static setToken(token) {
    return {
      type: Constants.SET_TOKEN,
      value: token
    };
  }

  /**
       * Nettoyage du tokenAdmin
       * @param {string} tokenAdmin TokenAdmin
       */
  static clearTokenAdmin(tokenAdmin) {
    return {
      type: Constants.CLEAR_TOKEN_ADMIN,
      value: tokenAdmin
    };
  }

  /**
   * Mise à jour du tokenAdmin
   * @param {string} tokenAdmin TokenAdmin
   */
  static setTokenAdmin(tokenAdmin) {
    return {
      type: Constants.SET_TOKEN_ADMIN,
      value: tokenAdmin
    };
  }
}



/**
 * Actions pour la gestion du user
 * @category Store
 * @hideconstructor
 */
class UserStoreAction {
  /**
   * Mise à jour du user
   * @param {string} user User
   */
  static setUser(user) {
    return {
      type: Constants.SET_USER,
      value: user
    };
  }
}

// Exports
export const clearToken = TokenStoreAction.clearToken;
export const setToken = TokenStoreAction.setToken;
export const clearTokenAdmin = TokenStoreAction.clearTokenAdmin;
export const setTokenAdmin = TokenStoreAction.setTokenAdmin;
export const setUser = UserStoreAction.setUser;

