import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from "@rmwc/drawer";
import { Fab } from "@rmwc/fab";
import { List, ListItem, ListItemGraphic } from "@rmwc/list";
import { ON_SECONDARY_COLOR, PRIMARY_COLOR, SECONDARY_COLOR } from "App/Constants";
import { setBackVisible } from "App/Header/Store/actions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { LanguageSelector } from "./LanguageSelector";

// #region FONCTION COMPOSANT
/**
* Composant permettant d'afficher le menu d'actions dans la carte
* @class
* @category Accueil
*/
function Menu() {
  // #region INITIALISATION
  // Initialisation des states
  const [open, setOpen] = useState(false);

  // Initialisation des langages
  const [t] = useTranslation();

  // Initialisation de l'historique de route
  const history = useHistory();

  // Initialisation du dispatch
  const dispatch = useDispatch();
  // #endregion

  // #region EVENTS
  const handleNavigate = (pathname) => {
    dispatch(setBackVisible(true));

    history.push(pathname);
    return;
  };
  // #endregion

  // #region INTERFACE
  return (
    <div >
      <div style={{ position: "absolute", left: 10, bottom: 120 }}>
        <Fab
          icon={{ icon: "menu", style: { fontSize: 36, color: PRIMARY_COLOR } }}
          onClick={() => setOpen(!open)}
          style={{
            backgroundColor: ON_SECONDARY_COLOR,
            border: "2px solid " + SECONDARY_COLOR,
          }}
        />
      </div>
      <Drawer
        modal
        onClose={() => setOpen(false)}
        open={open}
        style={{ position: "absolute", bottom: 0 }}
      >
        <DrawerHeader>
          <DrawerTitle>{t("accueil.labelMenu")}</DrawerTitle>
        </DrawerHeader>
        <DrawerContent>
          <List>
            {/* Mon compte */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <ListItemGraphic icon={{ icon: "person" }} style={{ alignSelf: "center", marginRight: 0, marginLeft: 15 }} />
              <ListItem onClick={() => handleNavigate("/user")}>
                {t("accueil.myAccount")}
              </ListItem>
            </div>
            {/* Historique d'achats */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <ListItemGraphic icon={{ icon: "payments" }} style={{ alignSelf: "center", marginRight: 0, marginLeft: 15 }} />
              <ListItem onClick={() => handleNavigate("/achats")}>
                {t("accueil.historic")}
              </ListItem>
            </div>
            {/* Sélection de language */}
            <LanguageSelector />
          </List>
        </DrawerContent>
      </Drawer>
    </div>
  );
  // #endregion
};
// #endregion

export { Menu };

