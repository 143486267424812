// #region CONSTANTES
// Types de paiement
export const PAYZEN = 2;
export const PAYZEN_TPE = 3;
export const TPE = "TPE";

export const MODES = {
  PARENT: 0,
  ENFANT: 1,
  TOTAL: 2
}

export const SERVICES_TYPES = {
  PARKING: 4,
  TAXE_SEJOUR: 10
}

export const UNITE_TYPES = {
  PERSONNE_24H: 12,
  EMPLACEMENT_24H: 13
}

export const TYPE_ACHAT = {
  TEST: "TEST",
  PROD: "PROD",
  INCONNU: "INCONNU"
}
// #endregion
