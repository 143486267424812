import "@rmwc/icon/styles";
import { CollapsibleList, SimpleListItem } from "@rmwc/list";
import { Modale } from "App/Modale/Components/Modale";
import PropTypes from "prop-types";
import { useState } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import "./DescriptifBorne.css";

const SLIDER_SETTINGS = {
  dots: true,
  infinite: true,
  slidesToShow: 3,
  // autoplay: true,
  // speed: 2000,
  // autoplaySpeed: 2000,
  // cssEase: "linear"
};

// #region FONCTION COMPOSANT
/**
* Composant permettant d'afficher le descriptif d'une borne dans l'écran d'achat
* @class
* @category DescriptifBorne
*/
function DescriptifBorne(props) {
  // #region INITIALISATION
  const {
    data
  } = props;

  const [selectIndex, setSelectIndex] = useState(false);
  const [ModaleDescriptionVisible, setModaleDescriptionVisible] = useState(false);
  // #endregion

  // #region INTERFACE
  return (
    <div>
      {ModaleDescriptionVisible
        ?
        <Modale
          onClose={() => { setSelectIndex(null); setModaleDescriptionVisible(false) }}
          visible={ModaleDescriptionVisible}
          width="350"
          height="350"
        >
          <img
            alt={"Description"}
            src={data.images[selectIndex]}
            style={{ width: "350px", height: "350px" }}
          />
        </Modale>
        :
        null
      }
      <CollapsibleList
        handle={
          <SimpleListItem
            text="Description"
            graphic="map"
            metaIcon="chevron_right"
          />
        }
      >
        <div className="DescriptifBorne-Container">
          <div className="DescriptifBorne-Text">
            {data.descriptif}
          </div>
          <div className="DescriptifBorne-Image-Container">
            <Slider {...SLIDER_SETTINGS}>
              {Object.keys(data.images).forEach((image, index) => (
                //descriptif.images.map((image, index) => (
                <img
                  alt={image}
                  className="DescriptifBorne-Image"
                  key={index}
                  onClick={() => { setSelectIndex(index); setModaleDescriptionVisible(true) }}
                  src={image}
                />
              ))}
            </Slider>
          </div>
        </div>
      </CollapsibleList>

    </div>
  );
  // #endregion
};
// #endregion

// #region PROPRIETES
/**
* Type des propriétés de {@link DescriptifBorne}
* @typedef {Object} DescriptifBorne.propTypes
* @property {object} data Données de description d'une borne
*/
DescriptifBorne.propTypes = {
  data: PropTypes.object.isRequired
};
// #endregion

export { DescriptifBorne };

