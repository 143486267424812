import { Fab } from "@rmwc/fab";
import { TextField } from "@rmwc/textfield";
import moment from "moment";
import PropTypes from "prop-types";
import { useEffect } from "react";

import '@rmwc/textfield/styles';
import { MODES } from "Achat/Constants";
import "./TimeSelector.css";

// #region CONSTANTES
const ADD = "add";
const SUB = "sub";
// #endregion

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher un TextField controllé par des boutons + et -
 * @category TimeSelector
 */
function TimeSelector(props) {
  //#region INITIALISATION
  // Récupération des props
  const {
    depassement,
    doShowButtons,
    mode,
    onChange,
    step,
    value
  } = props;
  // #endregion

  // #region UTILS
  // Transformation du temps selectionné au format hh:mm
  const getDuration = (value) => {
    let newValue = null;
    let newDuration = moment.duration(value, "seconds");
    let hours = newDuration.get("hours").toString();
    let days = newDuration.get("days").toString();
    days = days.length === 1 ? 0 + days : days;
    hours = hours.length === 1 ? "0" + hours : hours;
    let minutes = newDuration.get("minutes").toString();
    minutes = minutes.length === 1 ? "0" + minutes : minutes;
    newValue = hours + "h" + minutes;
    newValue = days !== "00" ? days + "j" + newValue : newValue;

    return newValue;
  }
  // #endregion

  // #region EVENTS
  // Mofification de la donnée
  const handleChange = (type) => {
    let newValue = null;

    switch (type) {
      case ADD:
        newValue = value + step;
        break;

      case SUB:
        newValue = value - step;
        break;

      default:
        break;
    }

    onChange(newValue);
  };
  // #endregion

  // #region HOOK D'EFFET
  // Initialisation de value
  useEffect(() => {
    if (value) {
      return;
    }

    onChange(step);
  }, [value, onChange, step]);
  // #endregion

  // #region INTERFACE
  return (
    <div className="TimeSelector-Container">
      {(doShowButtons && depassement && (value <= depassement)) || (mode === MODES.TOTAL)
        ?
        null
        :
        < Fab
          icon="remove"
          mini
          onClick={() => { handleChange(SUB) }}
        />

      }
      <TextField
        className="TimeSelector-TextField"
        disabled
        style={getDuration(value).length > 5 ? { width: "6.5rem" } : { width: "5rem" }}
        value={getDuration(value)}
      />
      {doShowButtons &&
        <Fab
          icon="add"
          mini
          onClick={() => handleChange(ADD)}
        />
      }
    </div>
  );
  // #endregion
};
// #endregion

// #region PROPRIETES
/**
 * Type des propriétés de {@link TimeSelector}
 * @typedef {Object} TimeSelector.propTypes
 * @property {boolean} [doShowButtons=true] True si les boutons doivent être affiché, false sinon
 * @property {function} onChange Fonction callback sur modification de la donnée
 * @property {number} step Step de la quantité a selectionner
 * @property {number} [value=null] Valeur à afficher dans l'input
*/
TimeSelector.propTypes = {
  doShowButtons: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

// Valeurs par défaut des propriétés
TimeSelector.defaultProps = {
  doShowButtons: true,
  value: null
}
// #endregion

export { TimeSelector };

