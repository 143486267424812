import { SnackbarQueue } from "@rmwc/snackbar";
import "@rmwc/snackbar/styles";
import { ThemeProvider } from "@rmwc/theme";
import { Accueil } from "Accueil/Components/Accueil";
import { Achat } from "Achat/Components/AchatNew";
import { HistoAchats } from "Achat/Components/HistoAchats";
import { Header } from "App/Header/Components/Header";
import { Loading } from "App/Loading/Components/Loading";
import { Queue } from 'App/Toast/Toast';
import { CreateAccount } from "Login/Components/CreateAccount";
import { Login } from "Login/Components/Login";
import moment from "moment";
import { Paiement } from "Paiement/Paiement";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import { ReferentielLoader } from "Referentiel/Components/ReferentielLoader";
import "./App.css";
import { clearToken, clearTokenAdmin } from "./Authentification/Store/actions";
import { INITIAL_STATE } from "./Authentification/Store/constants";
import { selectTokenAdminDate, selectTokenDate } from "./Authentification/Store/selectors";
import { ACCOUNT_MODE, DELAY_REQUEST, DELAY_VERIFICATION, ON_PRIMARY_COLOR, ON_SECONDARY_COLOR, PRIMARY_COLOR, SECONDARY_COLOR } from "./Constants";
import { ResetStore } from "./ResetStore";

/**
 * Point d'entrée de l'application
 * @private
 */
function App() {
  // #region INITIALISATION
  // Initialisation du state
  const [checkToken, setCheckToken] = useState(true);

  // Initialisation des selecteurs
  const tokenDate = useSelector((state) => selectTokenDate(state));
  const tokenDateAdmin = useSelector((state) => selectTokenAdminDate(state));

  // Initialisation des références
  const timerId = useRef(-2);

  // Récupération de l'historique
  const history = useHistory();

  // Initialisation du dispatch
  const dispatch = useDispatch();
  // #endregion

  // #region UTILS
  // Arrêt du timer
  const stopTimer = () => {
    if (timerId.current >= 0) {
      clearTimeout(timerId.current);
    };
  };
  // #endregion

  // #region HOOK D'EFFET
  // Détection de nouvelles données reçues
  useEffect(() => {
    if (!checkToken) {
      stopTimer();
      return;
    }

    timerId.current = -1;
    let date = tokenDate ? moment(tokenDate) : null;
    let dateAdmin = tokenDateAdmin ? moment(tokenDateAdmin) : null;

    // Si pas de token ou que la différence entre la date de récupération et la date du moment > 50 minutes, on lance a requête tout de suite
    if (
      (tokenDate && moment().diff(date, "minutes") > DELAY_REQUEST)
      ||
      (tokenDateAdmin && moment().diff(dateAdmin, "minutes") > DELAY_REQUEST)
    ) {
      dispatch(clearToken(INITIAL_STATE.token));
      dispatch(clearTokenAdmin(INITIAL_STATE.tokenAdmin));
      history.push({ pathname: "/connexion" });
      setCheckToken(false);
    }

    // On relance la vérification dans 5 minutes
    stopTimer();
    timerId.current = setTimeout(() => {
      setCheckToken(false);
      setCheckToken(true);
    }, DELAY_VERIFICATION);

  }, [checkToken, dispatch, history, tokenDate, tokenDateAdmin]);
  // #endregion

  // #region INTERFACE
  return (
    <div className="App-Container">
      <ThemeProvider
        options={{
          primary: PRIMARY_COLOR,
          primaryBg: PRIMARY_COLOR,
          secondary: SECONDARY_COLOR,
          onPrimary: ON_PRIMARY_COLOR,
          onSecondary: ON_SECONDARY_COLOR
        }}
        style={{ height: "100%" }}
      >
        <Header />
        <Switch>
          <Route path="/paiement">
            <Paiement />
          </Route>
          <Route path="/achats">
            <HistoAchats />
          </Route>
          <Route path="/achat">
            <Achat />
          </Route>
          <Route path="/nouveauCompte">
            <CreateAccount mode={ACCOUNT_MODE.creation} />
          </Route>
          <Route path="/connexion">
            <Login />
          </Route>
          <Route path="/user">
            <CreateAccount mode={ACCOUNT_MODE.modification} />
          </Route>
          <Route path="/">
            <Accueil />
          </Route>
        </Switch>
        <SnackbarQueue messages={Queue.messages} timeout={-1} />
        <Loading />
        <ReferentielLoader />
      </ThemeProvider>
      <ResetStore />
    </div>
  );
  // #endregion
}

export default App;
