import { URL_BASE as configUrl } from "Config.json";

// Chemins dans le site
export const MAP = "/Accueil";
export const BORNE = "/Borne";
export const DISTRIBUTION_EN_COURS = "/MaDistribution";
export const LOGIN = "/Connexion"

// URL
export const URL_BASE = configUrl; // "https://apialtekip.netbase.fr/api";
// export const URL_BASE = "http://localhost:8000/api";

// Couleur des paragraphes
export const PRIMARY_COLOR = "#000";
export const SECONDARY_COLOR = "#2bb5ef";
export const ON_PRIMARY_COLOR = "#31b0e6";
export const ON_SECONDARY_COLOR = "#FFF";


// Indice des TabBar
export const TAB_MAP = 0;
export const TAB_DISTRIBUTION = 1;

// Délai des requêtes
export const DELAY_REQUEST = 50; // 50 minutes
export const DELAY_VERIFICATION = 300000; // 5 minutes

// Couleurs
export const THEME = {
  BACKGROUND: "#DFDFDF",
  ON_VALID: "green",
  ON_ERROR: "red",
};

export const ACCOUNT_MODE = {
  creation: 0,
  modification: 1
};
