import { RESSOURCE_SERVICE_TYPE, RESSOURCE_UNITE_TYPES } from "../API/ReferentielAPI";
import { setDataServicesTypes, setDataUniteTypes } from "../Store/actions";
import { selectDataReferentielServiceTypes, selectDataReferentielUniteTypes } from "../Store/selectors";
import { selectTokenValue } from "App/Authentification/Store/selectors";
import { URL_BASE } from "App/Constants";
import md5 from "js-md5";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET } from "Request/Components/RequestUtils";
import { GET_LIST, useJsonRequest } from "Request/Components/useJsonRequest";
import { selectDataResourceReceived } from "Request/Store/selectors";

// #region CONSTANTES
const DELAY_REQUEST = 300000; // 5 minutes
// #endregion

// #region FONCTION COMPOSANT
/**
 * Composant permettant de stocker dans le store la liste des référentiels
 * @class
 * @category Referentiel
 */
function ReferentielLoader() {
  // #region INITIALISATION

  //  Initialisation du state
  const [getData, setGetData] = useState(true);

  // Récupération des sélecteurs
  const dataReferentielServiceTypes = useSelector((state) => selectDataReferentielServiceTypes(state)); // Données du référentiel
  const dataReferentielUniteTypes = useSelector((state) => selectDataReferentielUniteTypes(state)); // Données du référentiel
  const dataReceivedServiceType = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_SERVICE_TYPE)); // Données du serveur
  const dataReceivedUniteTypes = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_UNITE_TYPES)); // Données du serveur
  const token = useSelector((state) => selectTokenValue(state));

  // Initialisation des références
  const timerId = useRef(-2);

  // Récupération du dispacth
  const dispatch = useDispatch();
  // #endregion

  // #region UTILS
  // Arrêt du timer
  const stopTimer = () => {
    if (timerId.current >= 0) {
      clearTimeout(timerId.current);
    }
  };
  // #endregion

  // #region REQUEST
  // Requête pour récupérer les données servicesType
  const requestDataServiceType = useJsonRequest({
    command: GET,
    getMode: GET_LIST,
    resource: RESSOURCE_SERVICE_TYPE,
    silent: true,
    url: URL_BASE,
  });

  // Requête pour récupérer les données uniteType
  const requestDataUniteTypes = useJsonRequest({
    command: GET,
    getMode: GET_LIST,
    resource: RESSOURCE_UNITE_TYPES,
    silent: true,
    url: URL_BASE,
  });
  // #endregion

  // #region UTILS
  // Sauvegarde des réferentiels dans le store
  const saveData = useCallback((name, data) => {
    switch (name) {
      case RESSOURCE_SERVICE_TYPE:
        if (dataReferentielServiceTypes.hash === md5(data)) {
          return;
        }

        dispatch(setDataServicesTypes(data));
        break;

      case RESSOURCE_UNITE_TYPES:
        if (dataReferentielUniteTypes.hash === md5(data)) {
          return;
        }

        dispatch(setDataUniteTypes(data));
        break;

      default:
        break;
    }
  }, [dispatch, dataReferentielServiceTypes, dataReferentielUniteTypes]);
  // #endregion

  // #region HOOK D'EFFET
  // Appel des données
  useEffect(() => {
    if (!token || token.token || !getData) {
      stopTimer();
      return;
    }

    // On lance les requêtes tout de suite
    timerId.current = -1;
    requestDataServiceType();
    requestDataUniteTypes();

    // On relance la requête dans une minute
    stopTimer();
    timerId.current = setTimeout(() => {
      setGetData(false);
      setGetData(true);
    }, DELAY_REQUEST);
  }, [getData, token, requestDataServiceType, requestDataUniteTypes]);

  // Réception de nouvelles données service type
  useEffect(() => {
    if (!dataReceivedServiceType) {
      return;
    };

    saveData(RESSOURCE_SERVICE_TYPE, dataReceivedServiceType);
  }, [dataReceivedServiceType, saveData]);

  // Réception de nouvelles données unite types
  useEffect(() => {
    if (!dataReceivedUniteTypes) {
      return;
    };

    saveData(RESSOURCE_UNITE_TYPES, dataReceivedUniteTypes);
  }, [dataReceivedUniteTypes, saveData]);
  // #endregion

  // #region INTERFACE
  // Pas d'interface
  return null;
  // #endregion
};
// #endregion

export { ReferentielLoader };
