
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Countdown, { zeroPad } from "react-countdown";
import { useTranslation } from "react-i18next";
import { IconButton } from "@rmwc/icon-button";
import { RCTooltip } from "@rmwc/rc-tooltip";
import "@rmwc/rc-tooltip/styles"
import "@rmwc/icon-button/styles";
import "./DistributionCountDown.css";

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher un timer
 * @category Distribution
 * @param {DistributionCountDown.propTypes} props Les propriétés du composant
 */
function DistributionCountDown(props) {
  //#region INITIALISATION
  // Récupération des props
  const {
    detailAchat,
  } = props;

  // Traduction i18n
  const { t } = useTranslation();
  // #endregion

  //#region INTERFACE
  // Renvoie le JSX du timer
  const renderer = ({ days, hours, minutes, seconds }) => {
    if (days + hours + minutes + seconds === 0 && detailAchat.serviceType === 4) {
      return <span>{t("distribution.depassement")}</span>
    }

    if (days + hours + minutes === 0 && seconds !== 0) {
      return <span>{(`${days ? days + "j" : ""} ${zeroPad(hours, 2)}h ${zeroPad(minutes, 2)}m${zeroPad(seconds, 2)}`).trim()}</span>
    }

    // Render a countdown
    return <span>{(`${days ? days + "j" : ""} ${zeroPad(hours, 2)}h ${zeroPad(minutes, 2)}m`).trim()}</span>;
  };

  // Interface
  // Si pas de détail achat, pas de rendu
  if (!detailAchat) {
    return null;
  }

  return (
    (detailAchat.serviceType !== 10) ?
      <div className="DistributionCountDown-Container">
        <RCTooltip content="Durée restante">
          <IconButton icon="alarm" />
        </RCTooltip>
        <Countdown
          date={detailAchat.decalage ? moment(detailAchat.decalage).add(detailAchat.quantite * detailAchat.secondes, "second").toDate() : moment(detailAchat.dateCreation).add(detailAchat.quantite * detailAchat.secondes, "second").toDate()}
          precision={3}
          renderer={renderer}
        />
      </div>
      :
      <div className="DistributionCountDown-Container">
        {detailAchat.quantite + " " + t("distribution.unite")}
      </div>
  );
  // #endregion
};
// #endregion

// #region PROPRIETES
/**
 * Type des propriétés de {@link DistributionCountDown}
 * @typedef {Object} DistributionCountDown.propTypes
 * @property {object} detailAchat Le détail de l'achat
 */
DistributionCountDown.propTypes = {
  detailAchat: PropTypes.object.isRequired
};
// #endregion

export { DistributionCountDown };
