import { Fab } from "@rmwc/fab";
import "@rmwc/fab/styles";
import { ListItem, ListItemMeta, ListItemPrimaryText, ListItemSecondaryText, ListItemText } from "@rmwc/list";
import { MODES, SERVICES_TYPES } from "Achat/Constants";
import { showError } from "App/Toast/Toast";
import moment from "moment";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { QuantiteSelector } from "./QuantiteSelector";
import { TimeSelector } from "./TimeSelector";

//#region CONSTANTES
const TYPE_ACHAT = {
  MONTANT: "montant",
  PERSONNE: "personne",
  PRICE: "price",
  QUANTITE: "quantite",
  SELECTED: "selected",
  TRANCHE: "idTranche",
}
// #endregion

// #region FONCTION COMPOSANT
/**
* Composant permettant de
* @class
* @category AchatListItem
*/
function AchatListItem(props) {
  // #region INITIALISATION
  const {
    mode,
    onChange,
    service,
    serviceIndex,
    parentSelected = null
  } = props;

  const location = useLocation();

  // Initialisation des références
  const isActif = useRef(location.state.servicesActifs.includes(service.idService)); // service actif dans "ma distribution"
  const hasPlaces = useRef(service.places && service.places > 0); // calcul du nombre de places disponible

  // Traduction i18n
  const { t } = useTranslation();
  // #endregion

  // #region UTILS
  // Renvoie le tarif a afficher
  const getCurrentTarif = useCallback(() => {
    if (!service.quantite) {
      service.actualTranche = service.tarif.tranches[0];
      return;
    }

    service.tarif.find((item) => {
      let currentTarif = {};
      const testValue = moment().add(service.quantite * service.secondes, "second").toDate();

      return currentTarif;
    })
  }, [service]);

  // Renvoie la quantité limitée qui peut être achetée pour un service
  const getLimit = () => {
    const uniteLabel = service.tarif.libelleType.split(" ").slice(-1);
    const timeLimit = (service.quantiteLimite / 60);
    // TODO Gérer les quantites en litres / kWh

    return `${timeLimit} ${uniteLabel}`;
  };

  // Renvoie si le bouton + pour acheter doit être visible ou non
  const isCartInterfaceVisible = () => {
    let result = true;

    // Si le service parent est obligatoire, il faut au moins une unité achetée pour pouvoir acheter le service enfant
    if (parentSelected && parentSelected.serviceObligatoire && !parentSelected.quantite) {
      return false;
    }

    return result;
  };
  // #endregion

  // #region EVENTS
  // Modification du state
  const handleChange = (name, value) => {
    let d = { ...service };
    let newPrice = 0;

    // Si la valeure excède la limite du service, on renvoie un message d'erreur
    if (service.limite && (value > service.quantiteLimite)) {
      showError("Le service est limité à " + getLimit() + " maximum");
      return;
    }

    // Si pas de valeur, on déselectionne le service
    if (!value) {
      d[TYPE_ACHAT.SELECTED] = false;
      d[TYPE_ACHAT.QUANTITE] = 0;
      d[TYPE_ACHAT.MONTANT] = 0;
      onChange(d, serviceIndex);
      return;
    }

    switch (name) {
      case TYPE_ACHAT.PRICE:
        d[TYPE_ACHAT.QUANTITE] = Math.round(value / service.tarif.secondes);

        // let newPriceTime = (Math.round(value / service.tarif.secondes)) * service.tarif.prix;
        newPrice = (Math.round(value / service.tarif.secondes)) * service.actualTranche.prix;

        // d[name] = newPrice;
        d[TYPE_ACHAT.MONTANT] = newPrice;

        // Changement du montant total
        // if (!service.price) {
        //   onChange(newPriceTime);
        // } else {
        //   onChange(newPriceTime - service.price);
        // }

        // Changement de l'état de l'item si aucun montant
        // if (!value) {
        //   d[TYPE_ACHAT.SELECTED] = false;
        //   onSelectItem(serviceParent && serviceParent.selected ? true : false);
        // }
        break;

      case TYPE_ACHAT.QUANTITE:
        d[TYPE_ACHAT.QUANTITE] = value;

        // let newPriceQte = value * service.actualTranche.prix;
        newPrice = value * service.actualTranche.prix;

        d[TYPE_ACHAT.MONTANT] = newPrice;
        // d[TYPE_ACHAT.PRICE] = newPriceQte;
        // Changement du montant total
        // if (!service.price) {
        //   onChange(newPriceQte);
        // } else {
        //   onChange(newPriceQte - service.price);
        // }

        // Changement de l'état de l'item si aucun montant
        // if (!value) {
        //   d[TYPE_ACHAT.SELECTED] = false;
        //   onSelectItem(serviceParent && serviceParent.selected ? true : false);
        // }
        break;

      case TYPE_ACHAT.SELECTED:
        d[TYPE_ACHAT.SELECTED] = value;
        d[TYPE_ACHAT.MONTANT] = service.actualTranche.prix;
        d[TYPE_ACHAT.QUANTITE] = 1;
        break;

      case TYPE_ACHAT.PERSONNE:
        d[TYPE_ACHAT.QUANTITE] = value;
        let newPricePersonne = value * service.tarif.prix;
        d[TYPE_ACHAT.PRICE] = newPricePersonne;
        break;

      default:
        break;
    }

    // Ajout de la tranche de tarif
    d[TYPE_ACHAT.TRANCHE] = service.actualTranche.idTranche;
    // Modification du state
    onChange(d, serviceIndex);
  };

  // Vérification de la disponibilité en cas de rachat
  const handleDisponibilite = () => {
    if (isActif.current) {
      return true;
    } else if (!service.disponible) {
      if (service.idServiceType === SERVICES_TYPES.PARKING) {
        return hasPlaces.current;
      }
      return true;
    }
    return false;
  };
  // #endregion

  // #region HOOK D'EFFET
  useEffect(() => {
    // Renvoie le tarif a afficher
    const getCurrentTarif = () => {

      // Si une seule tranche ou pas de quantité
      if ((service.tarif && service.tarif.tranches.length === 1) || (!service.quantite)) {
        service.actualTranche = service.tarif.tranches[0];
        return;
      }


      // Sinon recherche de la bonne tranche de tarif
      service.tarif.tranches.find((item) => {
        let currentTarif = {};
        const testDate = moment().add(service.quantite * service.secondes, "second").toDate();

        return service.actualTranche = service.tarif.tranches[0];
      });
    };

    getCurrentTarif();
  }, [service]);
  // #endregion

  // #region INTERFACE
  // Affiche le bouton pour acheter un service
  const cartInterface = () => (
    isCartInterfaceVisible()
      ?
      // (
      //   (service.idServiceType === SERVICES_TYPES.TAXE_SEJOUR)
      //   &&
      //   (service.tarif.idUniteType === UNITE_TYPES.EMPLACEMENT_24H)
      // )
      //   ?
      //   <div>
      //     {t("achat.serviceTaxe")}
      //   </div>
      //   :
      //   service.idServiceType === SERVICES_TYPES.TAXE_SEJOUR
      //     ?
      //     <PersonneSelector
      //       doShowButtons={service.selected}
      //       // doShowButtons={!doShowSelectedOnly}
      //       onChange={(value) => { handleChange(TYPE_ACHAT.PERSONNE, value) }}
      //     />
      //     :
      handleDisponibilite()
        ?
        <Fab
          icon="add_shopping_cart"
          mini
          onClick={() => handleChange(TYPE_ACHAT.SELECTED, true)}
        />
        :
        <div>{t("achat.serviceOut")}</div>
      :
      null
  );

  // Affiche le sélecteur pour ajouter / diminuer la quantité de service sélectionné
  const timeOrQuantiteSelector = () => (
    service.tarif.idUniteType === 6
      ?
      <QuantiteSelector
        doShowButtons={service.selected && !MODES.TOTAL}
        onChange={(value) => { handleChange(TYPE_ACHAT.QUANTITE, value) }}
      />
      :
      <TimeSelector
        depassement={service.depassement * service.tarif.secondes}
        doShowButtons={service.selected && mode !== MODES.TOTAL}
        mode={mode}
        onChange={(value) => { handleChange(TYPE_ACHAT.PRICE, value) }}
        step={service.tarif.secondes}
        value={service.quantite * service.tarif.secondes}
      />
  );

  return (
    <ListItem
      key={service.idService}
      ripple={false}
      selected={false}
      style={{ height: "100px" }}
    >
      <ListItemText>
        <ListItemPrimaryText>
          {service.nom}
        </ListItemPrimaryText>
        {service.actualTranche &&
          <ListItemSecondaryText>
            {`${service.actualTranche.prix} ${service.tarif.symbole} / ${service.tarif.libelleType}`}
          </ListItemSecondaryText>
        }
        {service.limite ?
          <ListItemSecondaryText>
            {t("achat.serviceLimit") + getLimit()}
          </ListItemSecondaryText>
          :
          null
        }
        {service.places && service.places > 0 &&
          <ListItemSecondaryText>
            {service.places} {t("achat.places")}
          </ListItemSecondaryText>
        }
      </ListItemText>
      <ListItemMeta>
        {service.selected ? timeOrQuantiteSelector() : cartInterface()}
      </ListItemMeta>
    </ListItem>
  );
  // #endregion
};
// #endregion

// #region PROPRIETES
/**
* Type des propriétés de {@link AchatListItem}
* @typedef {Object} AchatListItem.propTypes
* @property {object} mode Mode de l'écran
* @property {object} parentSelected Service parent sélectionné
* @property {object} service L'item du service
* @property {number} serviceIndex L'index du service
*/
AchatListItem.propTypes = {
  mode: PropTypes.object.isRequired,
  parentSelected: PropTypes.object,
  service: PropTypes.object.isRequired,
  serviceIndex: PropTypes.number.isRequired
};
// #endregion

export { AchatListItem };

