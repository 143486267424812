import { DistributionCountDown } from "./DistributionCountDown";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Fab } from "@rmwc/fab";
import {
  ListDivider,
  ListItem,
  ListItemMeta,
  ListItemPrimaryText,
  ListItemText,
  ListItemSecondaryText
} from "@rmwc/list";

import "@rmwc/list/styles";
import "@rmwc/fab/styles";

//CSS
import "./DistributionListItem.css"
// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher un achat
 * @category Distribution
 * @param {DistributionListItem.propTypes} props Les propriétés du composant
 */
function DistributionListItem(props) {
  //#region INITIALISATION
  // Récupération des props
  const {
    detailAchat,
    handleRachat
  } = props;

  // Traduction i18n
  const { t } = useTranslation();
  // #endregion

  //#region INTERFACE
  return (
    <>
      <ListItem
        style={{ height: "90px" }}
        key={detailAchat.idDetailAchat}
        ripple={false}
      >
        <ListItemText>
          <ListItemText>
            <ListItemPrimaryText>{detailAchat.terminal}</ListItemPrimaryText>
            <ListItemSecondaryText>{detailAchat.service}</ListItemSecondaryText>
            {detailAchat.codeAcces ?
              <ListItemSecondaryText>{t("distribution.accesCode")} &nbsp;
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  {detailAchat.codeAcces}
                </span>
              </ListItemSecondaryText>
              : null
            }
          </ListItemText>
        </ListItemText>
        <ListItemMeta>
          <DistributionCountDown
            detailAchat={detailAchat}
          />
        </ListItemMeta>
      </ListItem>
      {detailAchat.serviceType !== 10
        ?
        <div className="DistributionListItem-Button">
          <Fab
            icon="shopping_cart"
            label={t("distribution.rachat")}
            onClick={() => handleRachat(detailAchat)}
          />
        </div>
        :
        null
      }
      <ListDivider />
    </>
  )
  // #endregion
};
// #endregion

// #region PROPRIETES
/**
 * Type des propriétés de {@link DistributionListItem}
      * @typedef {Object} DistributionListItem.propTypes
      * @property {object} detailAchat L'item de la catégorie
      */
DistributionListItem.propTypes = {
  detailAchat: PropTypes.object.isRequired
};
// #endregion

export { DistributionListItem };

