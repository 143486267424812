import React, { useState } from "react";
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from "@rmwc/drawer";
import { List, ListItem, ListItemGraphic } from "@rmwc/list";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// #region FONCTION COMPOSANT
/**
* Composant permettant de sélectionner la langue
* @class
* @category Accueil
*/
function LanguageSelector() {
  // #region INITIALISATION
  // Initialisation des langages
  const [t, i18n] = useTranslation();

  // Liste des langues
  const LANGUAGE_LIST = [
    {
      label: t("language.en"),
      icon: t("language.languageIconEN"),
      selected: i18next.resolvedLanguage === "en",
      value: "en"
    },
    {
      label: t("language.fr"),
      icon: t("language.languageIconFR"),
      selected: i18next.resolvedLanguage === "fr",
      value: "fr",
    },
    {
      label: t("language.esp"),
      icon: t("language.languageIconESP"),
      selected: i18next.resolvedLanguage === "esp",
      value: "esp",
    },
  ];

  // Initialisation des states ( après l'init du tableau )
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState(LANGUAGE_LIST);
  // #endregion

  // #region EVENTS
  // Changement de la langue
  const handleToggleLanguage = (item, index) => {
    let lng = [];
    // modification de la langue choisie
    i18n.changeLanguage(item.value);

    // Mise à jour de l'élément sélectionné
    language.map((mapItem, mapIndex) => {
      return lng.push({ ...mapItem, selected: index === mapIndex });
    });
    setLanguage(lng);

    setTimeout(() => {
      setOpen(false);
    }, 500);
  };
  // #endregion

  // #region INTERFACE
  // Affichage de la liste de langue
  const languageList = () => (
    language.map((item, index) => (
      <div
        key={index}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <ListItemGraphic
          icon={{ icon: item.icon, strategy: 'url' }}
          style={{ alignSelf: "center", marginRight: 0, marginLeft: 15 }}
        />
        <ListItem
          activated={item.selected}
          onClick={() => handleToggleLanguage(item, index)}
          selected={item.selected}
        >
          {item.label}
        </ListItem>
      </div>
    ))
  );

  return (
    <>
      <div
        onClick={() => setOpen(!open)}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <ListItemGraphic
          icon={{ icon: t("accueil.languageIcon"), strategy: 'url' }}
          style={{ alignSelf: "center", marginRight: 0, marginLeft: 15 }}
        />
        <ListItem onClick={() => null}>
          {t("accueil.labelLanguage")}
        </ListItem>
      </div>
      <Drawer
        modal
        onClose={() => setOpen(false)}
        open={open}
      >
        <DrawerHeader>
          <DrawerTitle>{t("accueil.labelLanguage")}</DrawerTitle>
        </DrawerHeader>
        <DrawerContent>
          <List>
            {languageList()}
          </List>
        </DrawerContent>
      </Drawer>
    </>
  );
  // #endregion
};
// #endregion

export { LanguageSelector };
