import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Fab } from "@rmwc/fab";
import { TextField } from "@rmwc/textfield";

import '@rmwc/textfield/styles';
import "./QuantiteSelector.css";

// #region CONSTANTES
const ADD = "add";
const SUB = "sub";
// #endregion

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher un TextField controllé par des boutons + et -
 * @category QuantiteSelector
 */
function QuantiteSelector(props) {
  //#region INITIALISATION
  // Récupération des props
  const {
    doShowButtons,
    onChange,
    value
  } = props;

  const [data, setData] = useState(value);
  // #endregion

  // #region EVENTS
  // Mofification de la donnée
  const handleChange = (type) => {
    let newValue = null;

    switch (type) {
      case ADD:
        newValue = data + 1;
        break;

      case SUB:
        newValue = data - 1;
        break;

      default:
        break;
    }
    setData(newValue);
    onChange(newValue);
  };
  // #endregion

  // #region HOOK D'EFFET
  // Initialisation de value
  useEffect(() => {
    if (data) {
      return;
    }

    onChange(1);
  }, [data, onChange]);

  // Modification dans l'input
  useEffect(() => {
    onChange(data)
  }, [onChange, data])

  // #endregion

  // #region INTERFACE
  return (
    <div className="QuantiteSelector-Container">
      {doShowButtons &&
        <Fab
          icon="remove"
          mini
          onClick={() => { handleChange(SUB) }}
        />
      }
      <TextField
        className="QuantiteSelector-TextField"
        onChange={(evt) => setData(evt.target.value)}
        style={data.length > 5 ? { width: "6rem" } : { width: "5rem" }}
        value={data}
      />
      {doShowButtons &&
        <Fab
          icon="add"
          mini
          onClick={() => handleChange(ADD)}
        />
      }
    </div>
  );
  // #endregion
};
// #endregion

// #region PROPRIETES
/**
 * Type des propriétés de {@link QuantiteSelector}
 * @typedef {Object} QuantiteSelector.propTypes
 * @property {boolean} [doShowButtons=true] True si les boutons doivent être affiché, false sinon
 * @property {function} onChange Fonction callback sur modification de la donnée
 * @property {number} [value=null] Valeur à afficher dans l'input
*/
QuantiteSelector.propTypes = {
  doShowButtons: PropTypes.bool.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

// Valeurs par défaut des propriétés
QuantiteSelector.defaultProps = {
  doShowButtons: true,
  value: 1
}
// #endregion

export { QuantiteSelector };
