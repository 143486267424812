import { Fab } from "@rmwc/fab";
import { Icon } from "@rmwc/icon";
import { TextField } from "@rmwc/textfield";
import { selectUser } from "App/Authentification/Store/selectors";
import { ACCOUNT_MODE, THEME, URL_BASE } from "App/Constants";
import { showError, showSuccess } from "App/Toast/Toast";
import { RULES_PASSWORD } from "Config.json";
import _ from "lodash";
import { RESOURCE_ACCOUNT, RESOURCE_ACCOUNT_INFO, RESSOURCE_PASSWORD } from "Login/API/LoginAPI";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GET, PATCH, POST } from "Request/Components/RequestUtils";
import { GET_SINGLE, useJsonRequest } from "Request/Components/useJsonRequest";
import { DATA_UPDATED } from "Request/Store/constants";
import { selectDataResourceReceived, selectStateDataResource } from "Request/Store/selectors";
import zxcvbn from "zxcvbn";
import "./CreateAccount.css";

// #region FONCTION COMPOSANT
/**
* Composant permettant de créer un compte utilisateur
* @class Account
* @category CreateAccount
*/
function CreateAccount(props) {
  // #region INITIALISATION
  const {
    mode
  } = props;

  // Initialisation du state
  const [confirmPassword, setConfirmPassword] = useState("");
  const [immatriculation, setImmatriculation] = useState("");
  const [login, setLogin] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordStatus, setPasswordStatus] = useState(RULES_PASSWORD);

  // Initialisation des sélecteurs
  const dataReceived = useSelector((state) => selectDataResourceReceived(state, RESOURCE_ACCOUNT_INFO)); // Données issues de la requête
  const dataUpdated = useSelector((state) => selectStateDataResource(state, RESSOURCE_PASSWORD)); // Données issues de la requête
  const userInfos = useSelector((state) => selectUser(state));

  // Traduction i18n
  const { t } = useTranslation();

  // Navigation
  const history = useHistory();
  // #endregion

  // #region UTILS
  const getLabel = () => {
    let label = "";

    switch (passwordStatus.strength) {
      case 0:
        label = "Mauvais"
        break;
      case 1:
        label = "Non sécurisé"
        break;
      case 2:
        label = "Peu sécurisé"
        break;
      case 3:
        label = "Assez sécurisé"
        break;
      case 4:
        label = "Très sécurisé"
        break;

      default:
        break;
    }

    return label;
  }

  // Validation du formulaire
  const isValidData = () => {
    if (ACCOUNT_MODE.creation) {
      const mailReg = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gim;
      if (!login.match(mailReg)) {
        showError(t("login.mailIncorrect"));
        return false;
      }
      // Champ obligatoire manquant
      if (!login || !password || !confirmPassword) {
        showError(t("login.mailPasswordRequired"));
        return false;
      }
    }

    // Mot de passe différent de la confirmation
    if (password !== confirmPassword) {
      showError(t("login.passwordNotSame"));
      return false;
    }

    if (parseInt(passwordStatus.strength) < 3) {
      showError(t("login.passwordForceTooLow"));
      return false;
    }

    return true;
  };

  // Validation du mot de passe
  const isValidPassword = (pwd) => {
    let rules = RULES_PASSWORD;

    // Vérification de la présence d'au moins un chiffre
    if (rules.needDigit) {
      rules.haveDigit = true;
      const haveDigitRegex = /[0-9]/gm;
      if (!password.match(haveDigitRegex)) {
        rules.haveDigit = false;
      }
    }

    // Vérification de la présence d'au moins un caractère spécial obligatoire
    if (rules.specialCharsList !== "") {
      rules.haveSpecialChars = true;
      // Création de la regex
      const specialCharRegex = "^.$|?*+()[]{}/\\";
      let regexWithSpecialChars = "";
      for (let i = 0; i < rules.specialCharsList.length; i++) {
        // Echappement des caractères spéciaux
        let char = rules.specialCharsList.charAt(i);
        if (specialCharRegex.indexOf(char) !== -1) {
          char = "\\" + char;
        }

        regexWithSpecialChars = regexWithSpecialChars + char;
        if (i !== rules.specialCharsList.length - 1) {
          regexWithSpecialChars = regexWithSpecialChars + "|";
        }
      }

      // Test de la présence d'un caractère spécial
      const haveSpecialRegex = new RegExp(regexWithSpecialChars, "gi");
      if (!password.match(haveSpecialRegex)) {
        rules.haveSpecialChars = false;
      }
    }

    // Vérification de la longueur du mot de passe
    if (rules.minlength) {
      rules.haveMinlength = password.length < rules.minlength ? false : true;
    }

    // Vérification de la présence d'au moins une minuscule et une majuscule
    if (rules.needMixcase) {
      rules.haveMixcase = true;
      const haveMixCaseRegex = /(?=.*[a-z])(?=.*[A-Z])/gm;
      if (!password.match(haveMixCaseRegex)) {
        rules.haveMixcase = false;
      }
    }

    // Mise à jour de la force du mot de passe
    rules.strength = zxcvbn(pwd).score;

    setPasswordStatus({ ...rules });
  };
  // #endregion

  // #region REQUEST
  const requestGetUserInfos = useJsonRequest({
    command: GET,
    getMode: GET_SINGLE,
    resource: RESOURCE_ACCOUNT_INFO,
    url: URL_BASE,
  });

  // Requête de création de compte
  const requestPostNewAccount = useJsonRequest({
    command: POST,
    resource: RESOURCE_ACCOUNT,
    tokenObligatory: false,
    tokenAdminObligatory: true,
    url: URL_BASE,
  });

  // Requête de modification de compte
  const requestPatchNewAccount = useJsonRequest({
    command: PATCH,
    resource: RESOURCE_ACCOUNT_INFO,
    tokenAdminObligatory: true,
    url: URL_BASE,
  });
  // #endregion

  // #region EVENTS
  const handleSubmitAccount = () => {
    let dataToSend = {};

    // Gestion du PATCH
    const submitPatch = () => {
      dataToSend = {
        email: login,
        name: lastname,
        plate: immatriculation,
        username: username,
      };

      // On ajoute le mot de passe si il n'est pas vide
      if (password) {
        dataToSend.password = password;
      }

      requestPatchNewAccount(null, dataToSend);
    };

    // Gestion du POST
    const submitPost = () => {
      if (!isValidData()) {
        return;
      }

      dataToSend = {
        email: login,
        name: lastname,
        password: password,
        plate: immatriculation,
        typeControl: 0,
        username: username,
      }

      requestPostNewAccount(dataToSend)
    }

    // Requête associée
    mode === ACCOUNT_MODE.creation ? submitPost() : submitPatch();
  };
  // #endregion

  // #region HOOK D'EFFET
  // Vérification du mot de passe
  useEffect(() => {
    isValidPassword(password);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password])

  // Requête des données du compte
  useEffect(() => {
    if (mode !== ACCOUNT_MODE.modification) {
      return;
    }

    requestGetUserInfos();
  }, [mode, requestGetUserInfos, userInfos]);

  // Récupération des données du compte
  useEffect(() => {
    if (!dataReceived) {
      return;
    }

    // Au retour du PATCH, on reçoit un objet et non un tableau
    let d = dataReceived && dataReceived[0] ? _.cloneDeep(dataReceived[0]) : dataReceived;

    setUsername(d.username);
    setImmatriculation(d.plate);
    setLastname(d.name);
    setLogin(d.email);
  }, [dataReceived]);

  // Réception de la mise à jour
  useEffect(() => {
    if (dataUpdated !== DATA_UPDATED) {
      return;
    }

    mode === ACCOUNT_MODE.creation ? showSuccess(t("login.accountCreated")) : showSuccess(t("login.accountUpdated"));

    history.goBack();
  }, [dataUpdated, mode, t, history]);
  // #endregion

  // #region INTERFACE
  const forcePasswordInterface = () => (
    <>
      <div className="CreateAccount-ForcePassword-Label">
        Force du mot de passe:  <div className="CreateAccount-ForcePassword-Strength" data-strength={passwordStatus.strength}>&nbsp;{getLabel()}</div>
      </div>
      <div className="CreateAccount-InfoSecurityContainer">
        <Icon icon={{ icon: "lock" }} />
        Votre mot de passe doit contenir :
      </div>
      <div className="CreateAccount-RulesContainer">
        {passwordStatus.minlength > 1
          ?
          <div className="CreateAccount-RulesRow">
            <Icon
              icon={{ icon: password && passwordStatus.haveMinlength ? "check_circle_outline" : "cancel" }}
              style={{ color: password && passwordStatus.haveMinlength ? THEME.ON_VALID : THEME.ON_ERROR }}
            />
            <div>&nbsp; Au moins {passwordStatus.minlength} caractères</div>
          </div>
          :
          null
        }
        {passwordStatus.needMixcase
          ?
          <div className="CreateAccount-RulesRow">
            <Icon
              icon={{ icon: password && passwordStatus.haveMixcase ? "check_circle_outline" : "cancel" }}
              style={{ color: password && passwordStatus.haveMixcase ? THEME.ON_VALID : THEME.ON_ERROR }}
            />
            <div>&nbsp; Au moins une majuscule et une minuscule</div>
          </div>
          :
          null
        }
        {passwordStatus.needDigit
          ?
          <div className="CreateAccount-RulesRow">
            <div className="CreateAccount-RulesResult">
              <Icon
                icon={{ icon: password && passwordStatus.haveDigit ? "check_circle_outline" : "cancel" }}
                style={{ color: password && passwordStatus.haveDigit ? THEME.ON_VALID : THEME.ON_ERROR }}
              />
            </div>
            <p>&nbsp; Au moins un chiffre</p>
          </div>
          :
          null
        }
        {passwordStatus.needSpecialChars && passwordStatus.specialCharsList !== ""
          ?
          <div className="CreateAccount-RulesRow">
            <Icon
              icon={{ icon: password && passwordStatus.haveSpecialChars ? "check_circle_outline" : "cancel" }}
              style={{ color: password && passwordStatus.haveSpecialChars ? THEME.ON_VALID : THEME.ON_ERROR }}
            />
            <div>
              &nbsp; Au moins un caractère parmi la liste suivante :
              <br />
              <span style={{ fontWeight: "bold" }}>{passwordStatus.specialCharsList.replace(" ", "(espace)")}</span>
            </div>
          </div>
          :
          null
        }
      </div>
    </>
  );


  return (
    <div className="CreateAccount-Container">
      <div className="CreateAccount-Input-Container">

        {mode === ACCOUNT_MODE.creation ?
          <>
            <div className="CreateAccount-Title">
              {t("login.accountTitleCreate")}
            </div>
            <TextField
              className="CreateAccount-Input"
              label={t("login.mail")}
              name="login"
              onChange={(item) => setLogin(item.target.value)}
              outlined
              required
              value={login}
            />
          </>
          :
          <div className="CreateAccount-Title">
            {t("login.accountTitleModify")}
          </div>
        }
        <TextField
          className="CreateAccount-Input"
          label={t("login.firstname")}
          name="username"
          onChange={(item) => setUsername(item.target.value)}
          outlined
          disabled={mode === ACCOUNT_MODE.modification}
          value={username}
        />
        <TextField
          className="CreateAccount-Input"
          label={t("login.lastname")}
          name="lastname"
          onChange={(item) => setLastname(item.target.value)}
          outlined
          value={lastname}
        />
        <TextField
          className="CreateAccount-Input"
          label={t("login.immatriculation")}
          name="immatriculation"
          onChange={(item) => setImmatriculation(item.target.value)}
          outlined
          value={immatriculation}
        />
        <TextField
          className="CreateAccount-Input"
          label={t("login.password")}
          name="password"
          onChange={(item) => setPassword(item.target.value)}
          outlined
          required
          value={password}
        />
        <div className="CreateAccount-ForcePassword-Container">
          <TextField
            className="CreateAccount-Input"
            label={t("login.confirmPassword")}
            name="password"
            onChange={(item) => setConfirmPassword(item.target.value)}
            outlined
            required
            invalid={password !== confirmPassword}
            value={confirmPassword}
          />
          <div className="CreateAccount-ConfirmPassword-Label"> Veuillez saisir un nouveau mot de passe et sa confirmation. </div>
          {/* Force du mot de passe */}
          {forcePasswordInterface()}
        </div>
        <div className="CreateAccount-Button-Container">
          <Fab
            label={t("login.validation")}
            onClick={() => handleSubmitAccount()}
          />
          {mode === ACCOUNT_MODE.creation
            ?
            <p className="Login-Link" onClick={() => history.goBack()} >
              {t("login.backToLogin")}
            </p>
            :
            null
          }
        </div>
      </div>
    </div>
  );
  // #endregion
};
// #endregion

export { CreateAccount };

