import { selectBackVisible } from "App/Header/Store/selectors";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import logoIsipay from "Resources/ISIPAY_LOGO.png";
import {
  TopAppBar,
  TopAppBarActionItem,
  TopAppBarFixedAdjust,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle
} from "@rmwc/top-app-bar";
import "@rmwc/top-app-bar/styles";

import "./Header.css";

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher le header
 * @category Header
 */
function Header() {
  // #region INITIALISATION
  // Inialisation des sélecteurs
  const backVisible = useSelector((state) => selectBackVisible(state));

  // Récupération de l'historique
  const history = useHistory();
  const location = useLocation();
  // #endregion

  // #region INTERFACE
  return (
    <>
      <TopAppBar
        className="Header-Container"
        style={{ visibility: location.pathname === "/connexion" ? "hidden" : "visible" }}
        fixed
      >
        <TopAppBarRow>
          <TopAppBarTitle className="Header-SectionContainer">
            <img
              alt="logo ISIPAY"
              className="Header-Logo"
              src={logoIsipay}
            />
          </TopAppBarTitle>
          {backVisible &&
            <>
              <TopAppBarSection alignStart>
                <TopAppBarActionItem
                  icon="arrow_back_ios_new"
                  onClick={() => history.replace("/")}
                  style={{ color: "#000" }}
                />
              </TopAppBarSection>
              <TopAppBarSection alignEnd>
                <div className="Header-EmptyContainer" />
              </TopAppBarSection>
            </>
          }
        </TopAppBarRow>
      </TopAppBar>
      <TopAppBarFixedAdjust />
    </>
  );
  // #endregion
};
// #endregion

export { Header };
