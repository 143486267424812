import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerF,
  useJsApiLoader
} from '@react-google-maps/api';
import { Fab } from '@rmwc/fab';
import { Icon } from '@rmwc/icon';
import { showError } from 'App/Toast/Toast';
import { API_KEY } from 'Map/Constants';
import { useEffect, useState } from 'react';
import { isMobile } from "react-device-detect";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { selectIcon } from 'Shared/Utils/Icon';
import "./Map.css";

// #REGION CONSTANTES
const containerStyle = {
  width: '100%',
  height: '91%',
}
// #endregion

function MapComponent(props) {
  // #region INITIALISATION
  const {
    center,
    defaultCenter,
    isMarkerShown,
    markers,
    onClick,
    terminauxSelector,
    zoom
  } = props;

  // Initialisation du state
  const [mapCenter, setMapCenter] = useState({ ...center });
  const [activeMarker, setActiveMarker] = useState(null);

  // Initialisation de l'API Google Maps
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY,
  });

  // Traduction i18n
  const { t } = useTranslation();
  // #endregion

  // #region EVENTS
  // Déplacement de la carte vers la borne sélectionnée ou retour vers l'utilisateur
  const handleMoveToBorne = (item) => {
    if (item) {
      if (!item.active) {
        showError(t("accueil.borneNotAvailable"));
        return;
      }

      setMapCenter({ lat: item.lat, lng: item.lng });
      setActiveMarker(item.value);
    } else {
      setMapCenter({ ...center });
    }
  };
  //#endregion

  // #region HOOK D'EFFET
  // Chargement de la carte
  useEffect(() => {
    setMapCenter(center);
  }, [center]);
  // #endregion

  // #region INTERFACE
  // Interface du tooltip d'un marker
  const TooltipInterface = (item) => (
    <InfoWindow onCloseClick={() => setActiveMarker(null)} headerContent={item.name} >
      <div className="Map-Tooltip-Container" key={item.id}>
        <h3>
          {item.name}
        </h3>
        <div className="Map-Tooltip-Description">
          <span>services disponibles : </span>
          <div className="Map-Tooltip-Services">
            {item.serviceList.map((serviceType, index) => (
              <Icon className="Map-Tooltip-Icon" key={index} icon={selectIcon(serviceType.toString())} height={32} width={32} />
            ))}
          </div>
        </div>
        <Fab
          label={t("accueil.mapButton")}
          icon="alarm"
          onClick={() => onClick(item)}
          style={{ boxShadow: "-1px 2px 3px -1px rgba(0, 0, 0, 0.5), 1px 4px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12)" }}
        />
      </div>
    </InfoWindow>
  );

  // Interface de la carte
  return (
    isLoaded ?
      <div style={{ width: "100%", height: "100%" }} >
        <GoogleMap
          {...props}
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={zoom}
        >
          {/* Sélecteur de borne */}
          <div className="Map-SelectBorne">
            <Select
              isClearable
              onChange={(value) => handleMoveToBorne(value)}
              options={terminauxSelector}
              placeholder={t("accueil.searchBorne")}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  width: isMobile ? "95%" : "50%"
                }),
                container: (baseStyles) => ({
                  ...baseStyles,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%"
                }),
              }}
            />
          </div>

          {/* Marqueurs */}
          {isMarkerShown &&
            <Marker position={center ? center : defaultCenter} />
          }
          {markers &&
            markers.map((item) => {
              return (
                <MarkerF
                  key={item.id}
                  position={item.location}
                  icon={{
                    ...item.icon,
                    anchor: new window.google.maps.Point(15, 20)
                  }}
                  onClick={() => { item.active ? setActiveMarker(item.id) : onClick(item) }}
                >
                  {
                    activeMarker === item.id
                      ?
                      // Interface du tooltip d'un marker
                      TooltipInterface(item)
                      :
                      null
                  }
                </MarkerF>
              )
            }
            )}
        </GoogleMap>
      </ div>
      :
      <></>
  )
  // #endregion
}

export { MapComponent };

