import {
  Document,
  Image,
  Page,
  Text,
  View,
} from '@react-pdf/renderer';
import { TPE } from "Achat/Constants";
import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import logo from "Resources/ISIPAY_LOGO.png";
import { styles } from "./TicketClientStyle";

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher le ticket Client
 * @category Ticket Client
 */
const TicketClient = ((props) => {
  //#region INITIALISATION
  const {
    achatData,
    achatPayzen,
    typePaiement,
  } = props;

  // Traduction i18n
  const { t } = useTranslation();
  // #endregion

  //#region INTERFACE
  // Retourne le ticket en fonction du type de paiement
  const getTicket = () => {
    if (typePaiement === TPE) {
      return (
        <View>
          <View style={styles.TicketPayzen_TextContainer}>
            <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextLeft]}>
              {achatPayzen}
            </Text>
          </View>
        </View>
      )
    }

    return (
      <View>
        <View style={styles.TicketPayzen_TextContainer}>
          <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextLeft]}>
            {`\n${t("ticket.dateBuy")}`}
          </Text>
          <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextRight]}>
            {`\n${moment(achatPayzen.date).local().format("DD/MM/YYYY / HH:mm:ss")}`}
          </Text>
        </View>
        <View style={styles.TicketPayzen_TextContainer}>
          <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextLeft]}>
            {t("ticket.shop")}
          </Text>
          <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextRight]}>
            {achatPayzen.shopId}
          </Text>
        </View>
        <View style={styles.TicketPayzen_TextContainer}>
          <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextLeft]}>
            {t("ticket.order")}
          </Text>
          <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextRight]}>
            {achatPayzen.transactionIdPayzen}
          </Text>
        </View>
        <View style={styles.TicketPayzen_TextContainer}>
          <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextLeft]}>
            {t("ticket.price")}
          </Text>
          <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextRight]}>
            {(achatPayzen.montant).toFixed(2)} {achatPayzen.devise}
          </Text>
        </View>
        <View style={styles.TicketPayzen_TextContainer}>
          <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextLeft]}>
            {t("ticket.cardNumber")}
          </Text>
          <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextRight]}>
            {achatPayzen.numeroCarte}
          </Text>
        </View>
        <View style={styles.TicketPayzen_TextContainer}>
          <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextLeft]}>
            {t("ticket.autorisationNumber")}
          </Text>
          <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextRight]}>
            {achatPayzen.autorisationPayzen}
          </Text>
        </View>
        <View style={styles.TicketPayzen_TextContainer}>
          <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextLeft]}>
            {t("ticket.type")}
          </Text>
          <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextRight]}>
            {achatPayzen.operationType}
          </Text>
        </View>
        {achatPayzen && achatPayzen.adresseMail ?
          <View style={styles.TicketPayzen_TextContainer}>
            <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextLeft]}>
              {t("ticket.mail")}
            </Text>
            <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextRight]}>
              {achatPayzen.adresseMail}
            </Text>
          </View>
          : null
        }
        {achatData && achatData.immatriculation ?
          <View style={styles.TicketPayzen_TextContainer}>
            <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextLeft]}>
              {t("ticket.immatriculation")}
            </Text>
            <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextRight]}>
              {achatData.immatriculation}
            </Text>
          </View>
          : null
        }
        {achatData && achatData.CodeAcces ?
          <View style={styles.TicketPayzen_CodeContainer}>
            <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextLeft]}>
              {t("ticket.code")}
            </Text>
            <Text style={[styles.TicketPayzen_Title, styles.TicketPayzen_TextRight]}>
              {achatData.CodeAcces}
            </Text>
          </View>
          : null
        }
      </View>
    )
  };

  // Interface
  if (!achatPayzen) {
    return null;
  }

  return (
    <Document>
      <Page
        size={[300, 380]}
        style={styles.TicketPayzen_Page}
      >
        <View>
          <Image
            src={logo}
            style={styles.TicketPayzen_Image}
          />
        </View>
        <View style={styles.TicketPayzen_SectionHeaderContainer}>
          <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextLeft, styles.TicketPayzen_Title]}>
            Ticket Client
          </Text>
        </View>
        {getTicket()}
        <View>
          <Text style={[styles.TicketPayzen_Text, styles.TicketPayzen_TextLeft, { marginTop: 50 }]}>
            Votre ticket a été envoyé sur votre boîte de réception ou votre dossier de courrier indésirable.
          </Text>
        </View>
      </Page>
    </Document >
  )
  // #endregion
});
// #endregion

// #region PROPRIETES
/**
 * Type des propriétés de {@link TicketClient}
 * @typedef {Object} TicketClient.propTypes
 * @property {object} [achatPayzen=null] Objet contenant l'achat effectué ou le ticket client
 * @property {object} [achatData={}] Objet contenant le retour de l'achat effectué ( pour le code accès )
 * @property {string} [typePaiement=null]
*/
TicketClient.propTypes = {
  achatPayzen: PropTypes.object,
  achatData: PropTypes.object,
  typePaiement: PropTypes.string,
};

// Valeurs par défaut des propriétés
TicketClient.defaultProps = {
  achatPayzen: null,
  achatData: {},
  typePaiement: null
};
// #endregion

export { TicketClient };

