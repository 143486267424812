import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from "App/App";
import { persistor, store } from "App/Store/configureStore";
import React from "react";
import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/es/integration/react";
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";


ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router>
        <App />
      </Router>
    </PersistGate>
  </ Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
