import { Icon } from "@rmwc/icon";
import { List } from "@rmwc/list";
import { RESSOURCE_ACHATS, RESSOURCE_DETAIL_ACHATS, RESSOURCE_SERVICES, RESSOURCE_TARIFS } from "Achat/API/AchatApi";
import { setDistribution } from "Achat/Store/actions";
import { selectAchat } from "Achat/Store/selectors";
import { URL_BASE } from "App/Constants";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectDataReferentielServiceTypes, selectDataReferentielUniteTypes } from "Referentiel/Store/selectors";
import { GET } from "Request/Components/RequestUtils";
import { GET_LIST_WITH_PARAMS, useJsonRequest } from "Request/Components/useJsonRequest";
import { selectDataResourceReceived } from "Request/Store/selectors";
import { DistributionList } from "./DistributionList";

import "@rmwc/icon/styles";
import "@rmwc/list/styles";
import "./Distribution.css";

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher les distributions en cours
 * @category Distribution
 */
function Distribution(props) {
  // #region INITIALISATION
  const {
    borne
  } = props;

  // Initialisation du state
  const [data, setData] = useState(null);

  // Traduction i18n
  const { t } = useTranslation();

  // Initialisation des selecteurs
  const achats = useSelector((state) => selectAchat(state)); // Achat stocké dans le store
  const dataReceivedAchats = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_ACHATS)); // Données issues de la requête
  const dataReceivedDetailAchats = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_DETAIL_ACHATS)); // Données issues de la requête
  const dataReceivedServices = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_SERVICES)); // Données issues de la requête
  const dataReceivedTarifs = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_TARIFS)); // Données issues de la requête
  const serviceTypes = useSelector((state) => selectDataReferentielServiceTypes(state));
  const uniteTypes = useSelector((state) => selectDataReferentielUniteTypes(state));

  // Initialisation des références
  const currentAchats = useRef([]); // Détail des achats du store
  const currentDetailAchats = useRef([]); // Détail des achats du store
  const services = useRef([]);  // Services des achats du store
  const servicesActifs = useRef([]) // Services activés
  const countReqResult = useRef(0);  // Compteur du nombre de requêtes réalisées
  const tarifs = useRef(null); // Tarifs des achats du store

  // Initialisation du dispatch
  const dispatch = useDispatch()

  // Initialisation de l'historique de navigation
  const history = useHistory();
  // #endregion

  // #region UTILS
  // Formatage des paramètres dans le cadre de l'envoi d'un tableau d'id
  const getParams = (prefixe, array) => {
    let params = [];
    let paramsString = "";

    array.forEach(value => {
      if (paramsString) {
        paramsString = paramsString + `&${prefixe}[]=${value}`;
      } else {
        paramsString = `${prefixe}[]=${value}`;
      }
    })
    params = [paramsString];

    return params;
  };

  // Initialisation des données
  const initializeData = useCallback(() => {
    if (countReqResult.current < 4) {
      return;
    }

    // Stockage des services achetés pour le rachat
    servicesActifs.current = _.uniq(_.map(currentDetailAchats.current, "idService"));

    let newData = currentDetailAchats.current;
    newData.forEach(detail => {
      // Récupération du service
      let service = services.current.find(serv => serv.idService === detail.idService);

      // Récupération du terminal
      let terminal = achats.find(achat => achat.idSite === service.idSite);
      service["terminal"] = terminal.terminalNom;

      // Récupération du serviceType
      let serviceType = serviceTypes.data.find(type => type.idServiceType === service.idServiceType);

      // Récupération du tarif
      let tarif = tarifs.current.find(tar => tar.idServiceType === serviceType.idServiceType && tar.idSite === service.idSite);

      // Récupération du type d'unité
      let uniteType = uniteTypes.data.find(type => type.idUniteType === tarif.idUniteType);

      // Ajout des informations au détail Achat
      detail["serviceType"] = serviceType.idServiceType;
      detail["service"] = service.nom;
      detail["serviceTypeNom"] = serviceType.libelle;
      detail["terminal"] = service.terminal;
      tarif["secondes"] = uniteType.secondes;
      detail["secondes"] = tarif.secondes;
      detail["decalage"] = terminal.decalage;
      detail["borne"] = terminal.idSite;

      // Si service de stationnement
      if (currentAchats.current.length) {
        detail["codeAcces"] = currentAchats.current.filter(achat => achat.idAchat === detail.idAchat)[0].CodeAcces ?? "";
        detail["immatriculation"] = currentAchats.current.filter(achat => achat.idAchat === detail.idAchat)[0].immatriculation ?? "";
      }

      // Calcul du statut de dépassement
      const date = moment(detail.dateCreation).add(detail.quantite * detail.secondes, "second").toDate();
      // Si pas dépassé
      moment(date).diff(moment()) > 0
        ? detail["depassement"] = 0
        : detail["depassement"] = moment().diff(moment(date), "days") + 1;
    });

    // Regroupement des services par serviceType
    let groupedData = _.groupBy(newData, "serviceType");
    let groupedArray = [];

    // Mise en forme du tableau des détail Achat par serviceType
    Object.keys(groupedData).forEach(key => groupedArray.push({
      id: key,
      name: groupedData[key][0].serviceTypeNom,
      detailAchats: groupedData[key]
    }));

    // Mise à jour du state
    setData(groupedArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // #endregion

  // #region REQUEST
  // Requêtes des achats
  const requestGetAchats = useJsonRequest({
    command: GET,
    getMode: GET_LIST_WITH_PARAMS,
    resource: RESSOURCE_ACHATS,
    url: URL_BASE,
  });


  // Requête de récupération de l'achat
  const requestGetDetailAchats = useJsonRequest({
    command: GET,
    getMode: GET_LIST_WITH_PARAMS,
    resource: RESSOURCE_DETAIL_ACHATS,
    url: URL_BASE,
  });

  // Requête de récupération de l'achat
  const requestGetDistribution = useJsonRequest({
    command: GET,
    getMode: GET_LIST_WITH_PARAMS,
    resource: RESSOURCE_DETAIL_ACHATS,
    url: URL_BASE,
  });

  // Requête de récupération des services
  const requestGetServices = useJsonRequest({
    command: GET,
    getMode: GET_LIST_WITH_PARAMS,
    resource: RESSOURCE_SERVICES,
    url: URL_BASE,
  });

  // Requête de récupération des tarifs
  const requestGetTarifs = useJsonRequest({
    command: GET,
    getMode: GET_LIST_WITH_PARAMS,
    resource: RESSOURCE_TARIFS,
    url: URL_BASE,
  });
  // #endregion

  // #region EVENTS
  const handleClickRachat = (achat) => {
    // Calcul de la validité de l'achat initial en cas de rachat
    const validiteAchat = achat.decalage ? moment(achat.decalage).add(achat.quantite * achat.secondes, "second").toDate() : moment(achat.dateCreation).add(achat.quantite * achat.secondes, "second").toDate();
    // sélection de la borne à utiliser
    const idBorne = borne.filter((borneItem) => borneItem.id === achat.borne)[0]
    history.push({
      pathname: "/achat",
      state: {
        borne: idBorne,
        codeAcces: achat.codeAcces,
        dateValidite: validiteAchat,
        immatriculation: achat.immatriculation,
        depassement: achat.depassement,
        rachat: true,
        servicesActifs: servicesActifs.current
      }
    });
  };
  // #endregion

  // #region HOOK D'EFFET
  // Nettoyage du store au chargement du composant
  useEffect(() => {
    setTimeout(() => {
      let newDistribution = []

      currentAchats.current.forEach(item => {
        newDistribution.push({ idAchat: item.idAchat, presence: item.presenceUser ?? false })
      });

      // Mise à jour des achats dans le store
      dispatch(setDistribution(newDistribution));
    }, 1500);
  }, [dispatch]);

  // Appel du serveur
  useEffect(() => {
    if (!achats.length) {
      return;
    }

    // Récupération des id des achats stockés dans le store
    let achatsId = _.uniq(_.map(achats, "id"));
    let paramsAchats = getParams("idAchat", achatsId);

    // Requête de récupération des détail
    if (paramsAchats.length) {
      requestGetDetailAchats(paramsAchats);
      requestGetAchats(paramsAchats);
    }

    // Récupération des id des terminaux
    let sites = _.uniq(_.map(achats, "idSite"));
    let paramsSites = getParams("idSite", sites);

    // Appel des tarifs et services correspondant
    if (sites.length) {
      requestGetServices(paramsSites);
      requestGetTarifs(paramsSites);
    }
  }, [requestGetAchats, requestGetDetailAchats, requestGetServices, requestGetTarifs, achats]);

  // Récupération des données de l'achat
  useEffect(() => {
    if (!dataReceivedDetailAchats) {
      return;
    }

    // Sauvegarde des détails des achats reçus
    currentDetailAchats.current = dataReceivedDetailAchats;

    // Alimentation du compteur de requêtes
    countReqResult.current++;

    // Initialisation du state
    initializeData();
  }, [initializeData, dataReceivedDetailAchats]);

  // Récupération des données de l'achat
  useEffect(() => {
    if (!dataReceivedAchats) {
      return;
    }

    // Alimentation du compteur de requêtes
    countReqResult.current++;

    // Sauvegarde des achats reçus
    currentAchats.current = dataReceivedAchats;

    // Initialisation du state
    initializeData();
  }, [initializeData, dataReceivedAchats]);

  // Récupération des services
  useEffect(() => {
    if (!dataReceivedServices) {
      return;
    }

    // Sauvegarde des services reçus
    services.current = dataReceivedServices;

    // Alimentation du compteur de requêtes
    countReqResult.current++;

    // Initialisation du state
    initializeData();
  }, [initializeData, dataReceivedServices]);

  // Récupération des tarifs
  useEffect(() => {
    if (!dataReceivedTarifs) {
      return;
    }

    // Sauvegarde des tarifs recus
    tarifs.current = dataReceivedTarifs;

    // Alimentation du compteur de requêtes
    countReqResult.current++;

    // Initialisation du state
    initializeData();
  }, [initializeData, dataReceivedTarifs]);
  // #endregion

  // #region INTERFACE
  // Interface
  return (
    <div className="Distribution-Container">
      {!data ?
        <>
          <Icon icon="not_interested" />
          <div>{t("distribution.placeholder")}</div>
        </>
        :
        <>
          <div className="Distribution-ScrollbarContainer">
            <PerfectScrollbar>
              <List twoLine>
                {data.map((categorie, index) => {
                  return (
                    <DistributionList
                      handleRachat={handleClickRachat}
                      categorie={categorie}
                      key={index}
                    />
                  )
                })}
              </List>
            </PerfectScrollbar>
          </div>
        </>
      }
    </div >
  );
  // #endregion
};
// #endregion

// #region PROPRIETES
/**
 * Type des propriétés de {@link Distribution},
 * @typedef {Object} Distribution.propTypes
 * @property {array} borne informations des bornes
 */
Distribution.propTypes = {
  borne: PropTypes.array.isRequired
};
// #endregion

export { Distribution };

